import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Step,
  StepLabel,
  Stepper,
  TextField,
} from '@mui/material'
import { Close } from 'assets/images'
import Button from 'components/Button/Index'
import Loader from 'components/Loader/Index'
import Switch from 'components/Switch/Index'
import Textfield from 'components/Textfield/Index'
import {
  establishmentList,
  selectedEstablishment,
} from 'features/establishment/establishment'
import { selectedRestaurant } from 'features/restaurant/restaurant'
import { useFormik } from 'formik'
import { Form, IGMAddFormRequest } from 'interfaces/guestmarketing'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import EmailEditor from 'react-email-editor'
import { useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import guestMarketingService from 'services/guest.marketing.service'
import { EMAIL_REGEX } from 'utility/constants'
import { resolveHTTP } from 'utility/helper'
import * as yup from 'yup'

interface dialogProps {
  open: boolean
  isEdit: boolean
  isView: boolean
  formIdForEdit?: number
  onClose?: React.MouseEventHandler<Element>
  closeDialog?: (event: boolean) => void
}
function LeadGeneratorFormDialog(props: dialogProps) {
  const { open, isEdit, isView, formIdForEdit, onClose, closeDialog } = props
  const formEditorRef = useRef(null)
  const emailEditorRef = useRef(null)
  const [loading, setLoading] = useState(true)
  const [emailLoading, setEmailLoading] = useState(true)
  const [temp, setTemp] = useState(null)
  const [emailTemp, setEmailTemp] = useState(null)
  const [jsonEditor, setJsonEditor] = useState(null)
  const [emailJsonEditor, setEmailJsonEditor] = useState(null)
  const allEstablishmentList = useSelector(establishmentList)
  const selectedRestaurantId = useSelector(selectedRestaurant)
  const selectedEstablishmentId = useSelector(selectedEstablishment)
  const [activeStep, setActiveStep] = useState(0)
  const [openTokenDialog, setOpenTokenDialog] = useState(false)
  const [urlToken, setUrlToken] = useState(null)

  const handlePrevious = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1)
  }

  const filteredEstablishments = allEstablishmentList
    .filter((x) => x.restaurantId === selectedRestaurantId)
    .map((x) => x.establishmentId)

  const getTemplateHtml = async (): Promise<{
    design: string
    html: string
  }> => {
    const { editor } = formEditorRef.current
    if (editor) {
      return new Promise((resolve) => {
        editor.exportHtml((data) => {
          const { design, html } = data
          setTemp(html)
          setJsonEditor(design)
          resolve({ design, html })
        })
      })
    }
    return { design: '', html: '' }
  }

  const getEmailTemplateHtml = async (): Promise<{
    design: string
    html: string
  }> => {
    const { editor } = emailEditorRef.current
    if (editor) {
      return new Promise((resolve) => {
        editor.exportHtml((data) => {
          const { design, html } = data
          setEmailTemp(html)
          setEmailJsonEditor(design)
          resolve({ design, html })
        })
      })
    }
    return { design: '', html: '' }
  }

  useEffect(() => {
    // Set loading to false when the editor is ready
    if (formEditorRef.current) {
      setLoading(false)
    }
  }, [])

  useEffect(() => {
    // Set loading to false when the editor is ready
    if (emailEditorRef.current) {
      setEmailLoading(false)
    }
  }, [])

  const handleSaveForm = async (formdata, resetForm) => {
    const [data] = await resolveHTTP(guestMarketingService.addForm(formdata))
    if (data?.data === 'success') {
      toast.success('Form is successfully created')
      setActiveStep(0)
      if (resetForm != null) {
        resetForm()
      }
      setEmailTemp(null)
      setEmailJsonEditor(null)
      setJsonEditor(null)
      setTemp(null)
      closeDialog(true)
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
    } else {
      toast.error('Something went wrong!')
    }
  }

  const handleNext = async (formdata, resetForm = null) => {
    switch (activeStep) {
      case 1: {
        const { design, html } = await getTemplateHtml()
        formdata.template = html
        formdata.templateDesign = JSON.stringify(design)

        if (formdata.isEmailRequired) {
          setActiveStep((prevActiveStep) => prevActiveStep + 1)
        } else if (isEdit) {
          const [data] = await resolveHTTP(
            guestMarketingService.editForm(formdata)
          )
          if (data?.data === 'success') {
            toast.success('Form is successfully updated')
            setActiveStep(0)
            resetForm()
            setEmailTemp(null)
            setEmailJsonEditor(null)
            setJsonEditor(null)
            setTemp(null)
            closeDialog(true)
          } else {
            toast.error('Something went wrong!')
          }
        } else if (isView) {
          setActiveStep(0)
          resetForm()
          setEmailTemp(null)
          setEmailJsonEditor(null)
          setJsonEditor(null)
          setTemp(null)
          closeDialog(true)
        } else {
          handleSaveForm(formdata, resetForm)
        }
        break
      }
      case 3: {
        const { design, html } = await getEmailTemplateHtml()
        formdata.emailTemplate = html
        formdata.emailTemplateDesign = JSON.stringify(design)

        if (isEdit) {
          const [data] = await resolveHTTP(
            guestMarketingService.editForm(formdata)
          )
          if (data?.data === 'success') {
            toast.success('Form is successfully updated')
            setActiveStep(0)
            resetForm()
            setEmailTemp(null)
            setEmailJsonEditor(null)
            setJsonEditor(null)
            setTemp(null)
            closeDialog(true)
            // eslint-disable-next-line @typescript-eslint/no-use-before-define
          } else {
            toast.error('Something went wrong!')
          }
        } else if (isView) {
          setActiveStep(0)
          resetForm()
          setEmailTemp(null)
          setEmailJsonEditor(null)
          setJsonEditor(null)
          setTemp(null)
          closeDialog(true)
        } else {
          handleSaveForm(formdata, resetForm)
        }
        break
      }
      default:
        setActiveStep((prevActiveStep) => prevActiveStep + 1)
        break
    }
  }

  const validationSchemaStepOne = yup.object({
    formTitle: yup
      .string()
      .required('Form Title is required')
      .max(256, 'Form Title cannot exceed 256 characters'),
    submissionNote: yup
      .string()
      .max(256, 'Submission Note cannot exceed 256 characters'),
  })

  const validationSchemaStepTwo = yup.object({
    subjectLine: yup.string().required('Subject Line is required'),
    previewText: yup.string().required('Preview Text is required'),
    senderName: yup.string().required('Sender Name is required'),
    senderEmail: yup
      .string()
      .required('Sender Email is required')
      .matches(EMAIL_REGEX, 'Sender Email is not valid'),
  })

  const CompareValidation = () => {
    switch (activeStep) {
      case 0:
        return validationSchemaStepOne
      case 2:
        return validationSchemaStepTwo
      default:
        return validationSchemaStepOne
    }
  }

  const handleSave = async (formdata: IGMAddFormRequest, resetForm) => {
    handleNext(formdata, resetForm)
  }

  const formik = useFormik<IGMAddFormRequest>({
    initialValues: {
      formId: 0,
      establishmentId: filteredEstablishments,
      formTitle: '',
      submissionNote: '',
      template: temp,
      templateDesign: jsonEditor,
      createdBy: Number(localStorage.getItem('loginId')) ?? null,
      isEmailRequired: false,
      subjectLine: '',
      previewText: '',
      senderName: '',
      senderEmail: '',
      addressEst: selectedEstablishmentId,
      emailTemplate: emailTemp,
      emailTemplateDesign: JSON.stringify(jsonEditor),
    },
    validationSchema: CompareValidation,
    onSubmit: (values, { resetForm }) => handleSave(values, resetForm),
  })

  const handleClose = (event: React.MouseEvent<Element>) => {
    onClose(event)
    formik.resetForm()
    setActiveStep(0)
    setEmailTemp(null)
    setEmailJsonEditor(null)
    setJsonEditor(null)
    setTemp(null)
  }

  const getFormForEdit = useCallback(
    async (formId): Promise<Form | undefined> => {
      try {
        const [data] = await Promise.all([
          guestMarketingService.getFormForEdit({ formId }),
        ])
        return data?.data?.data as Form
      } catch (error) {
        console.error(error)
        return null
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  const verifySenderEmail = useCallback(
    async (senderEmail) => {
      try {
        const [data] = await resolveHTTP(
          guestMarketingService.verifySenderEmail({
            email: senderEmail,
            name: senderEmail,
          })
        )
        if (data?.data.response === 'success') {
          setOpenTokenDialog(true)
        } else if (
          data?.data.response === 'Sender email or name is already verified.'
        ) {
          toast.success('Sender email is already verified.')
        } else if (data?.data.response === 'Sender Email can not be empty.') {
          toast.error('Sender email can not be empty.')
        } else if (data?.data.response === 'Email has already been sent.') {
          toast.success(
            'A verification email has already been sent. If you do not see it in your inbox, please check your spam or junk folder.'
          )
          setOpenTokenDialog(true)
        } else {
          console.error(data?.data.response)
        }
      } catch (error) {
        console.error(error)
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  const verifySenderToken = useCallback(
    async (token) => {
      try {
        const [data] = await resolveHTTP(
          guestMarketingService.verifySenderWithToken({
            verificationUrl: token,
          })
        )
        if (data?.data === 'success') {
          toast.success('Sender successfully verified.')
          setOpenTokenDialog(false)
        } else {
          console.error(data)
        }
      } catch (error) {
        console.error(error)
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  useEffect(() => {
    const fetchFormData = async () => {
      if ((isEdit || isView) && formIdForEdit > 0) {
        try {
          const response = await getFormForEdit(formIdForEdit)

          if (response) {
            // Set Formik values
            formik.setValues({
              formId: response.formId,
              establishmentId: [],
              formTitle: response.formTitle,
              submissionNote: response.submissionNote,
              template: response.template,
              templateDesign: response.templateDesign,
              createdBy: Number(localStorage.getItem('loginId')) ?? null,
              isEmailRequired: response.isEmailRequired,
              subjectLine: response.subjectLine,
              previewText: response.previewText,
              senderName: response.senderName,
              senderEmail: response.senderEmail,
              addressEst: response.addressEst,
              emailTemplate: response.emailTemplate ?? emailTemp,
              emailTemplateDesign: JSON.stringify(
                response.emailTemplateDesign ?? emailJsonEditor
              ),
            })

            setEmailTemp(response.emailTemplate)
            setEmailJsonEditor(JSON.parse(response.emailTemplateDesign))
            setJsonEditor(JSON.parse(response.templateDesign))
            setTemp(response.template)

            // Load the design after Formik values have been updated
            setTimeout(() => {
              formEditorRef.current.editor.loadDesign(
                JSON.parse(response.templateDesign)
              )
              emailEditorRef.current.editor.loadDesign(
                JSON.parse(response.emailTemplateDesign)
              )
            }, 0)
          }
        } catch (error) {
          console.error('Failed to fetch form data:', error)
        }
      }
    }

    fetchFormData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formIdForEdit, isEdit, isView])

  return (
    <>
      {/* lead generator form dialog start */}
      <Dialog
        className="primary-dialog full-height create-campaign-dialog"
        maxWidth="xl"
        fullWidth
        open={open}
      >
        <DialogTitle>
          <Button
            variant="text"
            title="Close"
            onClick={handleClose}
            className="icon-btn rounded"
            color="inherit"
          >
            <img src={Close} alt="Close" />
          </Button>
        </DialogTitle>
        <DialogContent className="pb-0">
          <div className="stepper-wrapper">
            <Stepper activeStep={activeStep} alternativeLabel>
              {/* Steps Configuration */}
              {[
                { label: 'Form Information', index: 0 },
                { label: 'Form Content', index: 1 },
                ...(formik.values.isEmailRequired
                  ? [
                      { label: 'Sender Information', index: 2 },
                      { label: 'Email Content', index: 3 },
                    ]
                  : []),
              ].map((step) => (
                <Step
                  key={step.label}
                  className={activeStep > step.index ? 'visited' : ''}
                  completed={false}
                  onClick={() => {
                    // Allow clicking only if the step index is valid
                    if (activeStep >= step.index || isEdit || isView) {
                      setActiveStep(step.index)
                    }
                  }}
                >
                  <StepLabel>{step.label}</StepLabel>
                </Step>
              ))}
            </Stepper>

            {/* stepper body */}
            <form onSubmit={formik.handleSubmit}>
              <div className="stepper-body">
                {/* form information start */}
                {activeStep === 0 && (
                  <div className="campaign-information">
                    <h4 className="title">Form Information</h4>
                    <p className="subtext">
                      Enter your details below to continue
                    </p>
                    <Grid container spacing={4}>
                      <Grid item xs={12}>
                        <div className="form-group">
                          <Textfield
                            variant="outlined"
                            label="Form Title"
                            name="formTitle"
                            disable={isView}
                            value={formik.values.formTitle}
                            onChange={formik.handleChange}
                            error={
                              !!formik.errors.formTitle &&
                              formik.touched.formTitle
                            }
                            helperText={
                              !!formik.errors.formTitle &&
                              formik.touched.formTitle
                                ? formik.errors.formTitle
                                : ''
                            }
                          />
                        </div>
                      </Grid>
                      <Grid item xs={12}>
                        <div className="form-group">
                          <Textfield
                            variant="outlined"
                            label="Customer Facing Submission Note (Optional)"
                            name="submissionNote"
                            multiline
                            rows={2}
                            disable={isView}
                            value={formik.values.submissionNote}
                            onChange={formik.handleChange}
                            error={
                              !!formik.errors.submissionNote &&
                              formik.touched.submissionNote
                            }
                            helperText={
                              !!formik.errors.submissionNote &&
                              formik.touched.submissionNote
                                ? formik.errors.submissionNote
                                : ''
                            }
                          />
                        </div>
                      </Grid>
                      <Grid item xs={12}>
                        <div className="form-group">
                          <span className="enable-two-fa">
                            Send Email on Submission
                          </span>
                          {'  '}
                          <Switch
                            name="isEmailRequired"
                            placement="bottom"
                            disabled={isView}
                            onChange={(e) => {
                              formik.setFieldValue(
                                'isEmailRequired',
                                e.target.checked
                              )
                            }}
                            checked={formik.values.isEmailRequired}
                          />
                        </div>
                      </Grid>
                      <Grid item xs={12}>
                        <Button
                          className="nav-btn"
                          variant="contained"
                          color="primary"
                          title={isView ? 'Next' : 'Save & Next'}
                          type="submit"
                        >
                          {isView ? 'Next' : 'Save & Next'}
                        </Button>
                      </Grid>
                    </Grid>
                  </div>
                )}
                {/* form information end */}
                {/* content builder start */}
                <div
                  className="custom-unlayer-editor"
                  style={
                    activeStep === 1
                      ? { display: 'block' }
                      : { display: 'none' }
                  }
                >
                  <div className="form">
                    <h4 className="title">Form Content</h4>
                    <p className="subtext">
                      Enter your details below to continue
                    </p>
                    <div
                      className="custom-unlayer-editor"
                      style={{ display: 'block' }}
                    >
                      {loading && <Loader />}
                      <EmailEditor
                        ref={formEditorRef}
                        onLoad={() => {
                          console.log('Editor loaded successfully!')
                        }}
                        minHeight="600px"
                        options={{
                          appearance: { theme: 'light' },
                          user: {
                            id: Number(localStorage.getItem('loginId')),
                          },
                          tools: {
                            form: {
                              enabled: true,
                              position: 0,
                              properties: {
                                fields: {
                                  value: [
                                    {
                                      name: 'email',
                                      type: 'email',
                                      label: 'Email',
                                      placeholder_text: 'Enter email here',
                                      show_label: true,
                                      required: true,
                                    },
                                    {
                                      name: 'first_name',
                                      type: 'text',
                                      label: 'First Name',
                                      placeholder_text: 'Enter first name here',
                                      show_label: true,
                                      required: true,
                                    },
                                    {
                                      name: 'last_name',
                                      type: 'text',
                                      label: 'Last Name',
                                      placeholder_text: 'Enter last name here',
                                      show_label: true,
                                      required: true,
                                    },
                                    {
                                      name: 'birth_day',
                                      type: 'date',
                                      label: 'Date of Birth',
                                      show_label: true,
                                      required: true,
                                    },
                                    {
                                      name: 'phone_number',
                                      type: 'text',
                                      label: 'Phone Number',
                                      placeholder_text:
                                        'Enter phone number here',
                                      show_label: true,
                                      required: true,
                                    },
                                    {
                                      name: 'zip_code',
                                      type: 'text',
                                      label: 'Postal Code',
                                      placeholder_text:
                                        'Enter postal code here',
                                      show_label: true,
                                      required: true,
                                    },
                                    {
                                      name: 'privacy_policy',
                                      type: 'checkbox',
                                      label: 'Privacy Policy',
                                      show_label: false,
                                      required: true,
                                      options: `<span>I've read and agree to <a href="https://fastabpayments.com/en/privacy-policy" target="_blank" rel="noopener noreferrer">Privacy Policy</a></span>`,
                                    },
                                  ],
                                },
                              },
                            },
                            social: { enabled: false },
                            video: { enabled: false },
                            html: { enabled: false },
                            menu: { enabled: false },
                            timer: { enabled: false },
                          },
                          features: {
                            blocks: false,
                          },
                          displayMode: 'web',
                        }}
                        onReady={(unlayer) => {
                          console.log(unlayer)
                          setLoading(false)
                        }}
                        projectId={255562}
                      />
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        marginTop: '10px',
                      }}
                    >
                      <Button
                        className="nav-btn  mt-10"
                        variant="contained"
                        color="primary"
                        title="Back"
                        onClick={handlePrevious}
                      >
                        Back
                      </Button>
                      <Button
                        className="nav-btn"
                        variant="contained"
                        color="primary"
                        title={isView ? 'Next' : 'Save & Next'}
                        type="submit"
                      >
                        {isView ? 'Next' : 'Save & Next'}
                      </Button>
                    </div>
                  </div>
                </div>
                {/* content builder end */}
                {/* email content start */}
                {activeStep === 2 && (
                  <div className="campaign-content">
                    <h4 className="title">Sender Information</h4>
                    <p className="subtext" style={{ marginBottom: '10px' }}>
                      Please enter your details below to continue.
                      <br />
                      Note: Guests will see this information
                    </p>
                    <div className="inner">
                      <div className="left">
                        <div className="form-group">
                          <Textfield
                            variant="outlined"
                            label="Subject Line"
                            disable={isView}
                            name="subjectLine"
                            value={formik.values.subjectLine}
                            onChange={formik.handleChange}
                            error={
                              !!formik.errors.subjectLine &&
                              formik.touched.subjectLine
                            }
                            helperText={
                              !!formik.errors.subjectLine &&
                              formik.touched.subjectLine
                                ? formik.errors.subjectLine
                                : ''
                            }
                          />
                        </div>
                        <div className="form-group">
                          <Textfield
                            variant="outlined"
                            label="Preview Text"
                            disable={isView}
                            name="previewText"
                            value={formik.values.previewText}
                            onChange={formik.handleChange}
                            error={
                              !!formik.errors.previewText &&
                              formik.touched.previewText
                            }
                            helperText={
                              !!formik.errors.previewText &&
                              formik.touched.previewText
                                ? formik.errors.previewText
                                : ''
                            }
                          />
                        </div>
                        <div className="form-group">
                          <Textfield
                            variant="outlined"
                            label="Sender Name"
                            name="senderName"
                            disable={isView}
                            value={formik.values.senderName}
                            onChange={formik.handleChange}
                            error={
                              !!formik.errors.senderName &&
                              formik.touched.senderName
                            }
                            helperText={
                              !!formik.errors.senderName &&
                              formik.touched.senderName
                                ? formik.errors.senderName
                                : ''
                            }
                          />
                        </div>
                        <div
                          className="form-group"
                          style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                          }}
                        >
                          <div style={{ flexBasis: '65%' }}>
                            <Textfield
                              variant="outlined"
                              label="Sender Email"
                              name="senderEmail"
                              disable={isView}
                              value={formik.values.senderEmail}
                              onChange={formik.handleChange}
                              error={
                                !!formik.errors.senderEmail &&
                                formik.touched.senderEmail
                              }
                              helperText={
                                !!formik.errors.senderEmail &&
                                formik.touched.senderEmail
                                  ? formik.errors.senderEmail
                                  : ''
                              }
                            />
                          </div>
                          <div style={{ flexBasis: '30%', marginTop: '-50px' }}>
                            <Button
                              className="nav-btn"
                              variant="contained"
                              color="primary"
                              title="Verify Sender Email"
                              onClick={() => {
                                verifySenderEmail(formik.values.senderEmail)
                              }}
                            >
                              Verify Sender Email
                            </Button>
                          </div>
                        </div>
                        <Grid item sm={3} xs={8}>
                          <Button
                            className="nav-btn"
                            variant="contained"
                            color="primary"
                            title="Back"
                            onClick={handlePrevious}
                          >
                            Back
                          </Button>
                        </Grid>
                        <Grid item sm={3} xs={8}>
                          <Button
                            className="nav-btn"
                            variant="contained"
                            color="primary"
                            title={isView ? 'Next' : 'Save & Next'}
                            type="submit"
                            // onClick={() => setShowContentBuilder(true)}
                          >
                            {isView ? 'Next' : 'Save & Next'}
                          </Button>
                        </Grid>
                      </div>
                      {/* <div className="right">
                        <img src={LargeLogoIcon} alt="FasTab" />
                      </div> */}
                    </div>
                  </div>
                )}
                {/* email content end */}

                {/* content builder start */}
                <div
                  className="custom-unlayer-editor"
                  style={
                    activeStep === 3
                      ? { display: 'block' }
                      : { display: 'none' }
                  }
                >
                  {emailLoading && <Loader />} {/* Loading overlay */}
                  <EmailEditor
                    ref={emailEditorRef}
                    onLoad={() => {
                      console.log('Editor loaded successfully!')
                    }}
                    options={{
                      appearance: { theme: 'light' },
                      tools: {
                        text: { enabled: true },
                        image: {
                          enabled: true,
                          properties: {
                            altText: {
                              value: 'Image',
                            },
                            maxWidth: 2038,
                            maxHeight: 1365,
                          },
                        },

                        menu: { enabled: false },
                        FirstName: { enabled: true },
                        html: { enabled: false },
                        'custom#redeem_button': { enabled: false },
                      },
                      customCSS:
                        '.blockbuilder-branding{display: none !important;}',
                      user: {
                        id: Number(localStorage.getItem('loginId')),
                      },
                    }}
                    onReady={(unlayer) => {
                      unlayer.setMergeTags({
                        first_name: {
                          name: 'First Name',
                          value: '{{first_name}}',
                        },
                        last_name: {
                          name: 'Last Name',
                          value: '{{last_name}}',
                        },
                      })
                      if (emailJsonEditor) {
                        unlayer.loadDesign(emailJsonEditor)
                      } else if (!isEdit && !isView) {
                        unlayer.loadTemplate(565673)
                      }
                      setEmailLoading(false)
                    }}
                    projectId={255562}
                  />
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      marginTop: '10px',
                    }}
                  >
                    <Button
                      className="nav-btn  mt-10"
                      variant="contained"
                      color="primary"
                      title="Back"
                      onClick={handlePrevious}
                    >
                      Back
                    </Button>
                    <Button
                      className="nav-btn"
                      variant="contained"
                      color="primary"
                      title={isView ? 'Close' : 'Create Form'}
                      type="submit"
                    >
                      {isView ? 'Next' : 'Create Form'}
                    </Button>
                  </div>
                </div>
                {/* content builder end */}

                {openTokenDialog && (
                  <Dialog
                    className="primary-dialog"
                    maxWidth="xs"
                    fullWidth
                    open={openTokenDialog}
                    onClose={() => setOpenTokenDialog(false)}
                  >
                    <DialogTitle>
                      <h5>Verify Sender Email</h5>
                      <Button
                        variant="text"
                        title="Close"
                        onClick={() => setOpenTokenDialog(false)}
                        className="icon-btn rounded"
                        color="inherit"
                      >
                        <img src={Close} alt="Close" />
                      </Button>
                    </DialogTitle>
                    <DialogContent className="pb-0">
                      <div className="form-group">
                        <p style={{ textAlign: 'center' }}>
                          Please check your email, copy and paste the URL link
                          you received in the email from SendGrid below. <br />
                          This is only one time process.
                        </p>
                        <TextField
                          variant="outlined"
                          label="Enter the link here..."
                          multiline
                          rows={5}
                          type="text"
                          onChange={(e) => {
                            setUrlToken(e.target.value)
                          }}
                        />
                      </div>
                    </DialogContent>
                    <DialogActions>
                      <div className="btn-list">
                        <Button
                          variant="contained"
                          color="primary"
                          title="Submit"
                          onClick={() => verifySenderToken(urlToken)}
                        >
                          Submit
                        </Button>
                      </div>
                    </DialogActions>
                  </Dialog>
                )}
              </div>
            </form>
          </div>
        </DialogContent>
      </Dialog>
      {/* lead generator form dialog end */}
    </>
  )
}

export default LeadGeneratorFormDialog
