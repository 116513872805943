import {
  AccordionDetails,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  InputAdornment,
  Slider,
  Step,
  StepLabel,
  Stepper,
  TextField,
  Typography,
} from '@mui/material'
import {
  BlankTemplate,
  Close,
  NewsLetterTemplate,
  OutlineCheck,
  PromotionTemplate,
  SimpleTemplate,
  WelcomeTemplate,
} from 'assets/images'
// import Autocomplete from 'components/Autocomplete/Index'
import Button from 'components/Button/Index'
import Switch from 'components/Switch/Index'
import Datepicker from 'components/Datepicker/Index'
import * as yup from 'yup'
import Select from 'components/Select/Index'
import Textfield from 'components/Textfield/Index'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import MultiSelect from 'components/MultiSelect/MultiSelect'
import guestMarketingService from 'services/guest.marketing.service'
import { establishmentList } from 'features/establishment/establishment'
import { useSelector } from 'react-redux'
import { selectedRestaurant } from 'features/restaurant/restaurant'
import { useFormik } from 'formik'
import {
  IGetCampaignForEditResponse,
  IGMAddCampaignRequest,
  IGMEmailListResponse,
} from 'interfaces/guestmarketing'
import { EMAIL_REGEX } from 'utility/constants'
import { resolveHTTP } from 'utility/helper'
import { toast } from 'react-toastify'
import EmailEditor from 'react-email-editor'
import Loader from 'components/Loader/Index'
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers-pro'
import { AdapterDateFns } from '@mui/x-date-pickers-pro/AdapterDateFns'
import moment from 'moment'
import {
  newsLetterContent,
  promotionTemplateContent,
  simpleContent,
  WelcomeContent,
} from './exportContent'

interface dialogProps {
  open: boolean
  isEdit: boolean
  isView: boolean
  loginId: number
  campaignIdForEdit?: number
  onClose?: React.MouseEventHandler<Element>
  closeDialog?: (event: boolean) => void
}
function CreateCampaignDialog(props: dialogProps) {
  const {
    open,
    isEdit,
    isView,
    campaignIdForEdit,
    onClose,
    closeDialog,
    loginId,
  } = props
  const [activeStep, setActiveStep] = useState(0)
  // const selectedCurrentEstablishment = useSelector(selectedEstablishment)
  const allEstablishmentList = useSelector(establishmentList)
  const selectedRestaurantId = useSelector(selectedRestaurant)
  // const [showContentBuilder, setShowContentBuilder] = useState(true)
  const [showLocationDetails, setShowLocationDetails] = useState(false)
  const [totalEmailRecords, setTotalEmailRecords] =
    useState<IGMEmailListResponse>()
  const [totalEmail, setTotalEmail] = useState(0)
  const [temp, setTemp] = useState(null)
  const [jsonEditor, setJsonEditor] = useState(null)
  // const [guestSpentEmailRecords, setGuestSpentEmailRecords] = useState<IGMEmailListResponse>()
  const emailEditorRef = useRef(null)
  const [loading, setLoading] = useState(true)
  const [chooseTemplate, setChooseTemplate] = useState<boolean>(true)
  const [confirmationDialog, setConfirmationDialog] = useState(false)
  const [openTokenDialog, setOpenTokenDialog] = useState(false)
  const [urlToken, setUrlToken] = useState(null)
  const [addressItems, setAddressItems] = useState(null)
  // const [emailListType, setEmailListType] = useState(1)

  useEffect(() => {
    // Set loading to false when the editor is ready
    if (emailEditorRef.current) {
      setLoading(false)
    }
  }, [])

  const getTemplateHtml = () => {
    const { editor } = emailEditorRef.current
    if (editor) {
      editor.exportHtml((data) => {
        const { design, html } = data
        setTemp(html)
        setJsonEditor(design)
        setActiveStep((prevActiveStep) => prevActiveStep + 1)
      })
    }
  }

  const convertUTCDateToLocalDate = (date) => {
    const newDate = new Date(
      new Date(date).getTime() + new Date(date).getTimezoneOffset() * 60 * 1000
    )
    newDate.setHours(0, 0, 0, 0)
    return newDate
  }

  const convertUTCDateToLocalDateBlastTime = (date) => {
    const utcDate = new Date(date)
    const offset = utcDate.getTimezoneOffset() * 60 * 1000
    return new Date(utcDate.getTime() - offset)
  }

  const handleSaveCampaign = async (formdata, resetForm) => {
    const [data] = await resolveHTTP(
      guestMarketingService.addCampaign(formdata)
    )
    if (data?.data === 'success') {
      toast.success('Campaign is successfully created')
      setActiveStep(0)
      if (resetForm != null) {
        resetForm()
      }
      setJsonEditor(null)
      setTemp(null)
      setChooseTemplate(true)
      closeDialog(true)
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
    } else {
      toast.error('Something went wrong!')
    }
  }

  const handleNext = async (
    formdata,
    resetForm = null,
    isDraftSubmit = null
  ) => {
    if (isDraftSubmit) {
      formdata.template = temp
      formdata.templateDesign = JSON.stringify(jsonEditor)
      formdata.isDraft = true
      if (formdata.triggerTypeId === 6) {
        formdata.emailList = []
        formdata.sendTo = []
      }
      if (formdata.emailListType === 2) {
        formdata.establishmentId = allEstablishmentList
          .filter((x) => x.restaurantId === selectedRestaurantId)
          .map((x) => x.establishmentId)
      }
      handleSaveCampaign(formdata, resetForm)
      return
    }
    // if (isDraftCampaign) {
    //   setActiveStep(4)
    // }
    switch (activeStep) {
      case 3:
        if (chooseTemplate) {
          setChooseTemplate(false)
        } else {
          getTemplateHtml()
        }
        break
      case 4: {
        formdata.template = temp
        formdata.templateDesign = JSON.stringify(jsonEditor)
        formdata.isDraft = false
        if (formdata.triggerTypeId === 6) {
          formdata.emailList = []
          formdata.sendTo = []
        }
        if (formdata.emailListType === 2) {
          formdata.establishmentId = allEstablishmentList
            .filter((x) => x.restaurantId === selectedRestaurantId)
            .map((x) => x.establishmentId)
        }
        if (formdata.triggerTypeId !== 8) {
          formdata.blastTime = formdata.startDate
        }
        if (isEdit) {
          const [data] = await resolveHTTP(
            guestMarketingService.editCampaign(formdata)
          )
          if (data?.data === 'success') {
            toast.success('Campaign is successfully updated')
            setActiveStep(0)
            resetForm()
            setJsonEditor(null)
            setTemp(null)
            setChooseTemplate(true)
            closeDialog(true)
            // eslint-disable-next-line @typescript-eslint/no-use-before-define
          } else {
            toast.error('Something went wrong!')
          }
        } else if (isView) {
          setActiveStep(0)
          resetForm()
          setJsonEditor(null)
          setChooseTemplate(true)
          setTemp(null)
          closeDialog(true)
        } else {
          handleSaveCampaign(formdata, resetForm)
        }
        break
      }
      default:
        setActiveStep((prevActiveStep) => prevActiveStep + 1)
        break
    }
  }
  const selectTemplate = (templateHtml, e) => {
    emailEditorRef.current.editor.loadDesign(templateHtml)
    handleNext(e)
  }
  const handleBlankTemplate = (e) => {
    emailEditorRef.current.editor.loadTemplate(565673)
    handleNext(e)
  }
  const handlePrevious = () => {
    if (!chooseTemplate && activeStep === 3 && !isView && !isEdit) {
      setChooseTemplate(true)
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep - 1)
    }
  }

  const handleEdit = (value) => {
    // if (value === 3) {
    //   setActiveStep(value)
    //   setShowContentBuilder(true)
    // }
    setActiveStep(value)
  }

  // const handleChange = (event: React.SyntheticEvent, newValue: string) => {
  //   setTabValue(newValue)
  // }

  const getCampaignForEdit = useCallback(
    async (campaignId): Promise<IGetCampaignForEditResponse | undefined> => {
      try {
        const [data] = await Promise.all([
          guestMarketingService.getCampaignForEdit({ campaignId }),
        ])
        return data?.data?.data as IGetCampaignForEditResponse
      } catch (error) {
        console.error(error)
        return null
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  const verifySenderEmail = useCallback(
    async (senderEmail) => {
      try {
        const [data] = await resolveHTTP(
          guestMarketingService.verifySenderEmail({
            email: senderEmail,
            name: senderEmail,
          })
        )
        if (data?.data.response === 'success') {
          setOpenTokenDialog(true)
        } else if (
          data?.data.response === 'Sender email or name is already verified.'
        ) {
          toast.success('Sender email is already verified.')
        } else if (data?.data.response === 'Sender Email can not be empty.') {
          toast.error('Sender email can not be empty.')
        } else if (data?.data.response === 'Email has already been sent.') {
          toast.success(
            'A verification email has already been sent. If you do not see it in your inbox, please check your spam or junk folder.'
          )
          setOpenTokenDialog(true)
        } else {
          console.error(data?.data.response)
        }
      } catch (error) {
        console.error(error)
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  const verifySenderToken = useCallback(
    async (token) => {
      try {
        const [data] = await resolveHTTP(
          guestMarketingService.verifySenderWithToken({
            verificationUrl: token,
          })
        )
        if (data?.data === 'success') {
          toast.success('Sender successfully verified.')
          setOpenTokenDialog(false)
        } else {
          console.error(data)
        }
      } catch (error) {
        console.error(error)
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )
  const filteredEstablishments = allEstablishmentList
    .filter((x) => x.restaurantId === selectedRestaurantId)
    .map((x) => ({ key: x.establishmentName, value: x.establishmentId }))

  const validationSchemaStepOne = yup.object({
    campaignName: yup.string().required('Campaign Name is required'),
    campaignDescription: yup
      .string()
      .required('Campaign Description is required'),
    startDate: yup.date().required('Start Date is required'),
    campaignTypeId: yup
      .number()
      .typeError('Campaign Type is required')
      .required('Campaign Type is required')
      .nullable(),
    triggerTypeId: yup
      .number()
      .typeError('Trigger Type is required')
      .required('Trigger Type is required')
      .nullable(),
  })

  const validationSchemaStepTwo = yup.object({
    establishmentId: yup.number().required('Location is required').nullable(),
    sendTo: yup
      .array()
      .compact()
      .nullable()
      .when('triggerTypeId', {
        is: 6,
        then: yup.array().compact().nullable(), // Not required when triggerTypeId is 6
        otherwise: yup
          .array()
          .compact()
          .min(1, 'At least one selection is required')
          .required('Send To is required')
          .nullable(),
      }),
    addressEst: yup
      .number()
      .typeError('Address is required')
      .required('Address is required')
      .nullable(),
  })

  const validationSchemaStepThree = yup.object({
    subjectLine: yup.string().required('Subject Line is required'),
    previewText: yup.string().required('Preview Text is required'),
    senderName: yup.string().required('Sender Name is required'),
    senderEmail: yup
      .string()
      .required('Sender Email is required')
      .matches(EMAIL_REGEX, 'Sender Email is not valid'),
  })

  const CompareValidation = () => {
    switch (activeStep) {
      case 0:
        return validationSchemaStepOne
      case 1:
        return validationSchemaStepTwo
      default:
        return validationSchemaStepThree
    }
  }

  const handleSave = async (formdata: IGMAddCampaignRequest, resetForm) => {
    handleNext(formdata, resetForm)
  }

  const formik = useFormik<IGMAddCampaignRequest>({
    initialValues: {
      campaignId: 0,
      establishmentId: [],
      campaignName: '',
      campaignDescription: '',
      startDate: convertUTCDateToLocalDate(new Date()),
      endDate: convertUTCDateToLocalDate(new Date()),
      campaignTypeId: 1,
      triggerTypeId: 1,
      sendTo: [],
      emailList: [],
      subjectLine: '',
      previewText: '',
      senderName: '',
      senderEmail: '',
      template: temp,
      templateDesign: JSON.stringify(jsonEditor),
      scheduled: null,
      starRatingsFrom: 1,
      starRatingsTo: 5,
      visits: null,
      amountSpent: null,
      lastVisit: null,
      createdBy: Number(localStorage.getItem('loginId')) ?? null,
      hasEndDate: false,
      isDraft: false,
      blastTime: convertUTCDateToLocalDate(new Date()),
      addressEst: null,
      emailListType: 1,
    },
    validationSchema: CompareValidation,
    onSubmit: (values, { resetForm }) => handleSave(values, resetForm),
  })

  const handleClose = (event: React.MouseEvent<Element>) => {
    onClose(event)
    formik.resetForm()
    setActiveStep(0)
    setJsonEditor(null)
    setTemp(null)
    setChooseTemplate(true)
    setConfirmationDialog(false)
    setShowLocationDetails(false)
  }

  const getEmailListTotal = useCallback(
    async (
      establishmentId,
      triggerTypeId,
      amountsSpent,
      starRatingFrom,
      starRatingTo,
      visit,
      lastVisits
    ) => {
      await Promise.all([
        await guestMarketingService.getEmailList({
          establishmentId:
            formik.values.emailListType === 1
              ? establishmentId
              : allEstablishmentList
                  .filter((x) => x.restaurantId === selectedRestaurantId)
                  .map((x) => x.establishmentId),
          triggerTypeId,
          amountSpent: amountsSpent,
          starRating: {
            starRatingFrom,
            starRatingTo,
          },
          visits: visit,
          lastVisit: lastVisits,
          emailListTypes: formik.values.emailListType,
        }),
      ])
        .then(([data]) => {
          setTotalEmailRecords(data?.data?.data)
          setAddressItems(
            Object.entries(data?.data?.data.addresses).map(([key, value]) => ({
              key: value,
              value: Number(key),
            }))
          )
        })
        .catch((error) => {
          console.error(error)
        })
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [formik.values.emailListType]
  )

  const handleTotalEmails = (selectedValues) => {
    let total = 0
    let selectedIds = []

    const dynamicKeys = Object.keys(totalEmailRecords.dynamicEmailLists)

    selectedValues.forEach((value) => {
      const key = dynamicKeys[value - 1] // Map numeric value back to string key
      if (key && totalEmailRecords.dynamicEmailLists[key]) {
        const emailList = totalEmailRecords.dynamicEmailLists[key]
        selectedIds = selectedIds.concat(emailList.map(Number)) // Convert IDs to numbers
      }
    })
    const uniqueSelectedIds = Array.from(new Set(selectedIds))
    total = uniqueSelectedIds.length

    // Update Formik values
    formik.setFieldValue('sendTo', selectedValues)
    setTotalEmail(total)
    formik.setFieldValue('emailList', selectedIds)
  }

  useEffect(() => {
    getEmailListTotal(
      formik.values.establishmentId,
      formik.values.triggerTypeId,
      formik.values.amountSpent,
      formik.values.starRatingsFrom,
      formik.values.starRatingsTo,
      formik.values.visits,
      formik.values.lastVisit
    )

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    formik.values.establishmentId,
    formik.values.triggerTypeId,
    formik.values.emailListType,
  ])

  useEffect(() => {
    if (formik.values.emailListType === 2) {
      formik.values.establishmentId = allEstablishmentList
        .filter((x) => x.restaurantId === selectedRestaurantId)
        .map((x) => x.establishmentId)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.emailListType])

  useEffect(() => {
    const fetchCampaignData = async () => {
      if ((isEdit || isView) && campaignIdForEdit > 0) {
        try {
          // Await the response from the getCampaignForEdit function
          const response = await getCampaignForEdit(campaignIdForEdit)
          // Ensure response is typed correctly and then set values in Formik
          if (response) {
            const selectedSources = []
            const combinedEmailList = []
            // Iterate over all keys in response dynamicEmailLists
            Object.keys(response.emailList.dynamicEmailLists).forEach((key) => {
              const emailList = response.emailList.dynamicEmailLists[key]

              if (emailList.length > 0) {
                // Find the index of the key in setTotalEmailRecords.dynamicEmailLists
                const sourceIndex = Object.keys(
                  response.fullEmailList.dynamicEmailLists
                ).findIndex((recordKey) => recordKey === key)

                if (sourceIndex !== -1) {
                  selectedSources.push(String(sourceIndex + 1)) // Map to index-based value
                  combinedEmailList.push(...emailList.map(Number)) // Convert IDs to numbers
                }
              }
            })

            setChooseTemplate(false)
            setTotalEmail(combinedEmailList.length)
            setTotalEmailRecords(response.fullEmailList)
            setTemp(response.campaign.template)
            setJsonEditor(JSON.parse(response.campaign.templateDesign))
            formik.setValues({
              campaignId: response.campaign.campaignId,
              establishmentId: response.establishmentId ?? [],
              campaignName: response.campaign.campaignName ?? '',
              campaignDescription: response.campaign.campaignDescription ?? '',
              startDate: response.campaign.startDate
                ? convertUTCDateToLocalDate(
                    new Date(response.campaign.startDate)
                  )
                : formik.initialValues.startDate,
              endDate: response.campaign.endDate
                ? convertUTCDateToLocalDate(new Date(response.campaign.endDate))
                : formik.initialValues.endDate,
              campaignTypeId: response.campaign.campaignTypeId ?? 1,
              triggerTypeId: response.campaign.campaignTriggerId ?? 1,
              sendTo: selectedSources.filter((source) => source !== '') ?? [],
              emailList: combinedEmailList ?? [],
              subjectLine: response.campaign.subjectLine ?? '',
              previewText: response.campaign.previewText ?? '',
              senderName: response.campaign.senderName ?? '',
              senderEmail: response.campaign.senderEmail ?? '',
              template: response.campaign.template ?? temp,
              templateDesign: JSON.stringify(
                response.campaign.templateDesign ?? jsonEditor
              ),
              scheduled: response.campaign.scheduled ?? null,
              starRatingsFrom: response.campaign.starRatingsFrom ?? 1,
              starRatingsTo: response.campaign.starRatingsTo ?? 5,
              visits: response.campaign.visits ?? null,
              amountSpent: response.campaign.amountSpent ?? null,
              lastVisit: response.campaign.lastVisit ?? null,
              createdBy:
                response.campaign.createdBy ??
                Number(localStorage.getItem('loginId')),
              hasEndDate: response.campaign.hasEndDate ?? false,
              isDraft: response.campaign.isDraft ?? false,
              blastTime: response.campaign.blastTime
                ? convertUTCDateToLocalDateBlastTime(
                    response.campaign.blastTime
                  )
                : formik.initialValues.blastTime,
              addressEst: response.campaign.addressEst ?? null,
              emailListType: response.campaign.emailListType,
            })

            setTimeout(() => {
              emailEditorRef.current.editor.loadDesign(
                JSON.parse(response.campaign.templateDesign)
              )
            }, 0)
          }
        } catch (error) {
          console.error('Failed to fetch campaign data:', error)
        }
      }
    }

    fetchCampaignData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [campaignIdForEdit, isEdit, isView])

  const getTriggerSummary = (triggerType) => {
    switch (triggerType) {
      case 1:
        return 'Birthday Trigger Type will trigger emails to guests 3 days before their birthday.'
      case 2:
        return `Review Trigger Type will trigger the emails to guests who leave review ratings between ${formik.values.starRatingsFrom} and ${formik.values.starRatingsTo} stars.`
      case 3:
        return `Milestone Based on Number of Visits Trigger Type will trigger the emails to guests when they reach the ${formik.values.visits}-visit milestone.`
      case 4:
        return `Milestone Based on Amount Spent Trigger Type will trigger the emails to guests when they reach the $${formik.values.amountSpent} spending milestone.`
      case 5:
        return `At Risk Trigger Type will trigger the emails to guests when they have not visited for more than ${formik.values.lastVisit} days.`
      case 6:
        return `Upon Registration  Trigger Type will trigger the emails to guests when they opt in to receive promotional emails.`
      case 8:
        return `Blast Trigger will trigger the emails to guests on ${moment(
          formik.values.blastTime
        ).format('DD-MMM-YY')} at ${moment(formik.values.blastTime).format(
          'hh:mm a'
        )}.`
      default:
        return '<p></p>'
    }
  }

  return (
    <>
      {/* create campaign dialog start */}
      <Dialog
        className="primary-dialog create-campaign-dialog full-height"
        maxWidth="xl"
        fullWidth
        open={open}
      >
        <DialogTitle>
          <Button
            variant="text"
            title="Close"
            onClick={(e) => {
              if (!isEdit && !isView && activeStep > 1) {
                setConfirmationDialog(true)
              } else {
                handleClose(e)
              }
            }}
            className="icon-btn rounded"
            color="inherit"
          >
            <img src={Close} alt="Close" />
          </Button>
        </DialogTitle>
        <DialogContent className="pb-0">
          {/* stepper wrapper start */}
          <div className="stepper-wrapper">
            <Stepper activeStep={activeStep} alternativeLabel>
              <Step
                className={activeStep > 0 ? 'visited' : ''}
                key="Campaign Information"
                completed={false}
                onClick={() => {
                  if (activeStep >= 0 || isEdit || isView) {
                    setActiveStep(0)
                  }
                }}
              >
                <StepLabel>Campaign Information</StepLabel>
              </Step>
              <Step
                className={activeStep > 1 ? 'visited' : ''}
                key="Location"
                completed={false}
                onClick={() => {
                  if (activeStep >= 1 || isEdit || isView) {
                    setActiveStep(1)
                  }
                }}
              >
                <StepLabel>Location & Email List</StepLabel>
              </Step>
              <Step
                className={activeStep > 2 ? 'visited' : ''}
                key="Email Information"
                completed={false}
                onClick={() => {
                  if (activeStep >= 2 || isEdit || isView) {
                    setActiveStep(2)
                  }
                }}
              >
                <StepLabel>Sender Information</StepLabel>
              </Step>
              <Step
                className={activeStep > 3 ? 'visited' : ''}
                key="Content"
                completed={false}
                onClick={() => {
                  if (activeStep >= 3 || isEdit || isView) {
                    setActiveStep(3)
                  }
                }}
              >
                <StepLabel>Content</StepLabel>
              </Step>
              <Step
                className={activeStep >= 4 ? 'visited' : ''}
                key="Review"
                completed={false}
                onClick={() => {
                  if (activeStep >= 4 || isEdit || isView) {
                    setActiveStep(4)
                  }
                }}
              >
                <StepLabel>Review</StepLabel>
              </Step>
            </Stepper>
            {/* stepper body */}
            <form onSubmit={formik.handleSubmit}>
              <div className="stepper-body">
                {/* campaign information start */}
                {activeStep === 0 && (
                  <div className="campaign-information">
                    <h4 className="title">Campaign Information</h4>
                    <p className="subtext">
                      Enter your details below to continue
                    </p>
                    <Grid container spacing={4}>
                      <Grid item xs={12}>
                        <div className="form-group">
                          <Textfield
                            variant="outlined"
                            label="Campaign Name"
                            name="campaignName"
                            disable={isView}
                            value={formik.values.campaignName}
                            onChange={formik.handleChange}
                            error={
                              !!formik.errors.campaignName &&
                              formik.touched.campaignName
                            }
                            helperText={
                              !!formik.errors.campaignName &&
                              formik.touched.campaignName
                                ? formik.errors.campaignName
                                : ''
                            }
                          />
                        </div>
                      </Grid>
                      <Grid item xs={12}>
                        <div className="form-group">
                          <Textfield
                            variant="outlined"
                            label="Campaign Description"
                            name="campaignDescription"
                            multiline
                            disable={isView}
                            rows={2}
                            value={formik.values.campaignDescription}
                            onChange={formik.handleChange}
                            error={
                              !!formik.errors.campaignDescription &&
                              formik.touched.campaignDescription
                            }
                            helperText={
                              !!formik.errors.campaignDescription &&
                              formik.touched.campaignDescription
                                ? formik.errors.campaignDescription
                                : ''
                            }
                          />
                        </div>
                      </Grid>
                      {/* {formik.values.sendTo === 2 && (
                      <Grid item xs={12}>
                        <div className="form-group">
                          <TextField
                            variant="outlined"
                            type="number"
                            label="Amount Spent (More than)"
                            name="amountSpent"
                            value={amountSpent}
                            onChange={(e) => {
                              setAmountSpent(Number(e.target.value))
                            }}
                            onBlur={formik.handleBlur}
                            InputProps={{
                              startAdornment: (
                                <InputAdornment
                                  position="start"
                                  style={{ borderRight: '0px' }}
                                >
                                  $
                                </InputAdornment>
                              ),
                            }}
                          />
                        </div>
                      </Grid>
                    )} */}
                      <Grid item xs={12}>
                        <div className="form-group">
                          <Datepicker
                            datePickerlabel="Start Date"
                            isPromoCodePage
                            disabled={isView}
                            minimumDate={new Date()}
                            value={
                              formik.values.startDate ??
                              convertUTCDateToLocalDate(new Date())
                            }
                            onChange={(date) => {
                              if (date) {
                                formik.setFieldValue(
                                  'startDate',
                                  convertUTCDateToLocalDate(date)
                                )
                              } else {
                                formik.setFieldValue(
                                  'startDate',
                                  convertUTCDateToLocalDate(new Date())
                                )
                              }
                            }}
                            setFilterSelectedDate={(newDate) =>
                              formik.setFieldValue('startDate', newDate)
                            }
                          />
                        </div>
                      </Grid>
                      <Grid item xs={12}>
                        <div className="form-group">
                          <span className="enable-two-fa">
                            Include End Date
                          </span>
                          {'  '}
                          <Switch
                            name="hasEndDate"
                            placement="bottom"
                            disabled={isView}
                            onChange={(e) => {
                              formik.setFieldValue(
                                'hasEndDate',
                                e.target.checked
                              )
                            }}
                            checked={formik.values.hasEndDate}
                          />
                        </div>
                      </Grid>
                      {formik.values.hasEndDate && (
                        <Grid item xs={12}>
                          <div className="form-group">
                            <Datepicker
                              datePickerlabel="End Date"
                              isPromoCodePage
                              disabled={isView}
                              minimumDate={new Date()}
                              value={
                                formik.values.endDate ??
                                convertUTCDateToLocalDate(new Date().getDate())
                              }
                              onChange={(date) => {
                                if (date) {
                                  formik.setFieldValue(
                                    'endDate',
                                    convertUTCDateToLocalDate(date)
                                  )
                                } else {
                                  formik.setFieldValue(
                                    'endDate',
                                    convertUTCDateToLocalDate(
                                      new Date().getDate()
                                    )
                                  )
                                }
                              }}
                              setFilterSelectedDate={(newDate) =>
                                formik.setFieldValue('endDate', newDate)
                              }
                            />
                          </div>
                        </Grid>
                      )}

                      {/* <Grid item xs={12}>
                        <div className="form-group">
                          <Select
                            handleSelectValue={(value) => {
                              formik.setFieldValue('campaignTypeId', value)
                            }}
                            formikValue={formik.values.campaignTypeId}
                            disabled={isView}
                            items={[
                              {
                                key: 'Trigger',
                                value: 1,
                              },
                              {
                                key: 'Schedule',
                                value: 2,
                              },
                            ]}
                            label="Campaign Type"
                            showError={
                              !!formik.errors.campaignTypeId &&
                              formik.touched.campaignTypeId
                            }
                            errorText={
                              !!formik.errors.campaignTypeId &&
                              formik.touched.campaignTypeId
                                ? formik.errors.campaignTypeId
                                : ''
                            }
                          />
                        </div>
                      </Grid> */}
                      {formik.values.campaignTypeId === 2 && (
                        <Grid item xs={12}>
                          <div className="form-group">
                            <TextField
                              variant="outlined"
                              type="number"
                              label="Scheduled after (hours)"
                              name="scheduled"
                              disabled={isView}
                              value={formik.values.scheduled}
                              onChange={formik.handleChange}
                              error={
                                !!formik.errors.scheduled &&
                                formik.touched.scheduled
                              }
                              helperText={
                                !!formik.errors.scheduled &&
                                formik.touched.scheduled
                                  ? formik.errors.scheduled
                                  : ''
                              }
                              onBlur={formik.handleBlur}
                            />
                          </div>
                        </Grid>
                      )}
                      <Grid item xs={12}>
                        <div className="form-group">
                          <Select
                            handleSelectValue={(value) => {
                              formik.setFieldValue('emailListType', value)
                            }}
                            disabled={isView}
                            formikValue={formik.values.emailListType}
                            items={[
                              {
                                key: 'Location Specific',
                                value: 1,
                              },
                              {
                                key: 'Lead Specific',
                                value: 2,
                              },
                            ]}
                            label="Email List Type"
                          />
                        </div>
                      </Grid>
                      <Grid item xs={12}>
                        <div className="form-group">
                          <Select
                            handleSelectValue={(value) => {
                              formik.setFieldValue('triggerTypeId', value)
                            }}
                            disabled={isView}
                            formikValue={formik.values.triggerTypeId}
                            items={[
                              {
                                key: 'Birthday',
                                value: 1,
                              },
                              {
                                key: 'Review',
                                value: 2,
                              },
                              {
                                key: 'Milestone Based on Number of Visits',
                                value: 3,
                              },
                              {
                                key: 'Milestone Based on Amount Spent',
                                value: 4,
                              },
                              {
                                key: 'At Risk',
                                value: 5,
                              },
                              {
                                key: 'Blast',
                                value: 8,
                              },
                              {
                                key: 'Upon Registration',
                                value: 6,
                              },
                              // {
                              //   key: 'Upon Payment',
                              //   value: 7,
                              // },
                            ]}
                            label="Trigger Type"
                            showError={
                              !!formik.errors.triggerTypeId &&
                              formik.touched.triggerTypeId
                            }
                            errorText={
                              !!formik.errors.triggerTypeId &&
                              formik.touched.triggerTypeId
                                ? formik.errors.triggerTypeId
                                : ''
                            }
                          />
                        </div>
                      </Grid>
                      {formik.values.triggerTypeId === 8 && (
                        <>
                          <Grid item sm={12} xs={12}>
                            <div className="form-group">
                              <LocalizationProvider
                                dateAdapter={AdapterDateFns}
                              >
                                <DateTimePicker
                                  label="Time of Blast"
                                  disabled={isView}
                                  value={
                                    formik.values.blastTime
                                      ? moment(formik.values.blastTime)
                                      : moment(formik.values.startDate)
                                  }
                                  minDate={formik.values.startDate}
                                  maxDate={
                                    formik.values.hasEndDate
                                      ? formik.values.endDate
                                      : null
                                  }
                                  onChange={(time) => {
                                    if (time) {
                                      // Set both date and time as a Date object
                                      formik.setFieldValue(
                                        'blastTime',
                                        moment(time).toDate()
                                      )
                                    } else {
                                      formik.setFieldValue('blastTime', null)
                                    }
                                  }}
                                  renderInput={(params) => (
                                    <TextField {...params} />
                                  )}
                                />
                              </LocalizationProvider>
                            </div>
                          </Grid>
                        </>
                      )}
                      {formik.values.triggerTypeId === 2 && (
                        <>
                          <Grid item sm={12} xs={12}>
                            <div className="form-group">
                              <Typography gutterBottom>
                                Star Ratings Range
                              </Typography>
                              <Slider
                                value={[
                                  formik.values.starRatingsFrom,
                                  formik.values.starRatingsTo,
                                ]}
                                disabled={isView}
                                onChange={(e, newValue) => {
                                  formik.setFieldValue(
                                    'starRatingsFrom',
                                    newValue[0]
                                  )
                                  formik.setFieldValue(
                                    'starRatingsTo',
                                    newValue[1]
                                  )
                                }}
                                onBlur={() => {
                                  if (formik.values.starRatingsFrom < 1)
                                    formik.setFieldValue('starRatingsFrom', 1)
                                  if (formik.values.starRatingsTo > 5)
                                    formik.setFieldValue('starRatingsTo', 5)
                                }}
                                valueLabelDisplay="auto"
                                min={1}
                                max={5}
                              />
                            </div>
                          </Grid>
                        </>
                      )}
                      {formik.values.triggerTypeId === 3 && (
                        <Grid item xs={12}>
                          <div className="form-group">
                            <TextField
                              variant="outlined"
                              type="number"
                              disabled={isView}
                              label="Visits"
                              name="visits"
                              value={formik.values.visits}
                              onChange={(e) => {
                                if (Number(e.target.value) > 0) {
                                  formik.setFieldValue(
                                    'visits',
                                    Number(e.target.value)
                                  )
                                } else {
                                  formik.setFieldValue('visits', null)
                                }
                              }}
                              onBlur={formik.handleBlur}
                            />
                          </div>
                        </Grid>
                      )}
                      {formik.values.triggerTypeId === 4 && (
                        <Grid item xs={12}>
                          <div className="form-group">
                            <TextField
                              variant="outlined"
                              type="number"
                              disabled={isView}
                              label="Amount Spent"
                              name="amountSpent"
                              value={formik.values.amountSpent}
                              onChange={(e) => {
                                if (Number(e.target.value) > 0) {
                                  formik.setFieldValue(
                                    'amountSpent',
                                    Number(e.target.value)
                                  )
                                } else {
                                  formik.setFieldValue('amountSpent', null)
                                }
                              }}
                              onBlur={formik.handleBlur}
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment
                                    position="start"
                                    style={{ borderRight: '0px' }}
                                  >
                                    $
                                  </InputAdornment>
                                ),
                              }}
                            />
                          </div>
                        </Grid>
                      )}
                      {formik.values.triggerTypeId === 5 && (
                        <Grid item xs={12}>
                          <div className="form-group">
                            <TextField
                              variant="outlined"
                              type="number"
                              disabled={isView}
                              label="Days Since Last Visit"
                              name="lastVisit"
                              value={formik.values.lastVisit}
                              onChange={(e) => {
                                if (Number(e.target.value) > 0) {
                                  formik.setFieldValue(
                                    'lastVisit',
                                    Number(e.target.value)
                                  )
                                } else {
                                  formik.setFieldValue('lastVisit', null)
                                }
                              }}
                              onBlur={formik.handleBlur}
                            />
                          </div>
                        </Grid>
                      )}
                      <Grid item xs={12}>
                        <Button
                          className="nav-btn"
                          variant="contained"
                          color="primary"
                          title={isView ? 'Next' : 'Save & Next'}
                          type="submit"
                          // onClick={handleNext}
                        >
                          {isView ? 'Next' : 'Save & Next'}
                        </Button>
                      </Grid>
                    </Grid>
                  </div>
                )}
                {/* campaign information end */}

                {/* campaign location start */}
                {activeStep === 1 && (
                  <div className="campaign-location">
                    {!showLocationDetails && (
                      <>
                        <h4 className="title">Location & Email List</h4>
                        <p className="subtext">Please Select Locations</p>
                        <Grid container spacing={4}>
                          {formik.values.emailListType === 1 && (
                            <Grid item xs={12}>
                              <div className="form-group">
                                <MultiSelect
                                  label="Location"
                                  disabled={isView}
                                  showError={
                                    !!formik.errors.establishmentId &&
                                    formik.touched.establishmentId
                                  }
                                  errorText={
                                    !!formik.errors.establishmentId &&
                                    formik.touched.establishmentId
                                      ? 'Location is required'
                                      : ''
                                  }
                                  defaultValue={formik.values.establishmentId?.map(
                                    (xx) => xx.toString()
                                  )}
                                  items={filteredEstablishments}
                                  handleSelectValue={(value) => {
                                    formik.setFieldValue(
                                      'establishmentId',
                                      value
                                        ?.map((x) => Number(x))
                                        .filter((x) => x > 0) ?? []
                                    )
                                  }}
                                />
                              </div>
                            </Grid>
                          )}
                          {formik.values.triggerTypeId !== 6 && (
                            <Grid item xs={12}>
                              <div className="form-group">
                                <MultiSelect
                                  handleSelectValue={(value) => {
                                    handleTotalEmails(value)
                                  }}
                                  disabled={isView}
                                  defaultValue={formik.values.sendTo}
                                  showError={
                                    !!formik.errors.sendTo &&
                                    formik.touched.sendTo
                                  }
                                  errorText={
                                    !!formik.errors.sendTo &&
                                    formik.touched.sendTo
                                      ? formik.errors.sendTo.toString()
                                      : ''
                                  }
                                  items={Object.keys(
                                    totalEmailRecords.dynamicEmailLists
                                  ).map((key, index) => ({
                                    key: `${key} (${
                                      totalEmailRecords.dynamicEmailLists[key]
                                        ?.length || 0
                                    })`,
                                    value: index + 1, // Use 1-based index as value
                                  }))}
                                  label="Send To"
                                />
                              </div>
                            </Grid>
                          )}

                          <Grid item xs={12}>
                            <div className="form-group">
                              <Select
                                handleSelectValue={(value) => {
                                  formik.setFieldValue('addressEst', value)
                                }}
                                formikValue={formik.values.addressEst}
                                disabled={isView}
                                items={addressItems}
                                label="Location Address For Email"
                                showError={
                                  !!formik.errors.addressEst &&
                                  formik.touched.addressEst
                                }
                                errorText={
                                  !!formik.errors.addressEst &&
                                  formik.touched.addressEst
                                    ? formik.errors.addressEst
                                    : ''
                                }
                              />
                            </div>
                          </Grid>
                          {/* <div className="form-group">
                        <MultiSelect
                          formikValue={formik.values.establishmentId}
                          items={filteredEstablishments}
                          label="Select A Location"
                          onChange={(selectedValues) =>
                            formik.setFieldValue(
                              'establishmentId',
                              selectedValues
                            )
                          }
                        />
                      </div> */}
                          {/* <div className="form-group">
                        <Checkbox
                          name="rememberMe"
                          label="Auto Add New Locations"
                        />
                      </div> */}
                          <Grid item sm={6} xs={12}>
                            <Button
                              className="nav-btn"
                              variant="contained"
                              color="primary"
                              title="Back"
                              onClick={handlePrevious}
                            >
                              Back
                            </Button>
                          </Grid>
                          <Grid item sm={6} xs={12}>
                            <Button
                              className="nav-btn"
                              variant="contained"
                              color="primary"
                              title={isView ? 'Next' : 'Save & Next'}
                              type="submit"
                              // onClick={handleNext}
                            >
                              {isView ? 'Next' : 'Save & Next'}
                            </Button>
                          </Grid>
                        </Grid>
                      </>
                    )}
                  </div>
                )}
                {/* campaign location end */}

                {/* campaign content start */}
                {activeStep === 2 && (
                  <div className="campaign-content">
                    {/* {!showContentBuilder && ( */}

                    <h4 className="title">Sender Information</h4>
                    <p className="subtext" style={{ marginBottom: '10px' }}>
                      Please enter your details below to continue.
                      <br />
                      Note: Guests will see this information
                    </p>
                    <div className="inner">
                      <div className="left">
                        <div className="form-group">
                          <Textfield
                            variant="outlined"
                            label="Subject Line"
                            disable={isView}
                            name="subjectLine"
                            value={formik.values.subjectLine}
                            onChange={formik.handleChange}
                            error={
                              !!formik.errors.subjectLine &&
                              formik.touched.subjectLine
                            }
                            helperText={
                              !!formik.errors.subjectLine &&
                              formik.touched.subjectLine
                                ? formik.errors.subjectLine
                                : ''
                            }
                          />
                        </div>
                        <div className="form-group">
                          <Textfield
                            variant="outlined"
                            label="Preview Text"
                            disable={isView}
                            name="previewText"
                            value={formik.values.previewText}
                            onChange={formik.handleChange}
                            error={
                              !!formik.errors.previewText &&
                              formik.touched.previewText
                            }
                            helperText={
                              !!formik.errors.previewText &&
                              formik.touched.previewText
                                ? formik.errors.previewText
                                : ''
                            }
                          />
                        </div>
                        <div className="form-group">
                          <Textfield
                            variant="outlined"
                            label="Sender Name"
                            name="senderName"
                            disable={isView}
                            value={formik.values.senderName}
                            onChange={formik.handleChange}
                            error={
                              !!formik.errors.senderName &&
                              formik.touched.senderName
                            }
                            helperText={
                              !!formik.errors.senderName &&
                              formik.touched.senderName
                                ? formik.errors.senderName
                                : ''
                            }
                          />
                        </div>
                        <div
                          className="form-group"
                          style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                          }}
                        >
                          <div style={{ flexBasis: '65%' }}>
                            <Textfield
                              variant="outlined"
                              label="Sender Email"
                              name="senderEmail"
                              disable={isView}
                              value={formik.values.senderEmail}
                              onChange={formik.handleChange}
                              error={
                                !!formik.errors.senderEmail &&
                                formik.touched.senderEmail
                              }
                              helperText={
                                !!formik.errors.senderEmail &&
                                formik.touched.senderEmail
                                  ? formik.errors.senderEmail
                                  : ''
                              }
                            />
                          </div>
                          <div style={{ flexBasis: '30%', marginTop: '-50px' }}>
                            <Button
                              className="nav-btn"
                              variant="contained"
                              color="primary"
                              title="Verify Sender Email"
                              onClick={() => {
                                verifySenderEmail(formik.values.senderEmail)
                              }}
                            >
                              Verify Sender Email
                            </Button>
                          </div>
                        </div>
                        <Grid item sm={3} xs={8}>
                          <Button
                            className="nav-btn"
                            variant="contained"
                            color="primary"
                            title="Back"
                            onClick={handlePrevious}
                          >
                            Back
                          </Button>
                        </Grid>
                        <Grid item sm={3} xs={8}>
                          <Button
                            className="nav-btn"
                            variant="contained"
                            color="primary"
                            title={isView ? 'Next' : 'Save & Next'}
                            type="submit"
                            // onClick={() => setShowContentBuilder(true)}
                          >
                            {isView ? 'Next' : 'Save & Next'}
                          </Button>
                        </Grid>
                      </div>
                      {/* <div className="right">
                        <img src={LargeLogoIcon} alt="FasTab" />
                      </div> */}
                    </div>
                  </div>
                )}

                {
                  <div
                    className="campaign-content"
                    style={
                      activeStep === 3
                        ? { display: 'block' }
                        : { display: 'none' }
                    }
                  >
                    <div
                      className="choose-template"
                      style={
                        chooseTemplate
                          ? { display: 'block' }
                          : { display: 'none' }
                      }
                    >
                      <h4 className="title">Choose Template</h4>
                      <AccordionDetails>
                        <Grid container spacing={3}>
                          <Grid item lg={3} md={6} sm={4} xs={6}>
                            <div className="template-details mt-15">
                              <img src={BlankTemplate} alt="Birthday" />
                              <p>Blank</p>
                              <Button
                                variant="contained"
                                color="primary"
                                title="Select"
                                className="choose-btn"
                                size="small"
                                onClick={handleBlankTemplate}
                              >
                                Select
                              </Button>
                            </div>
                          </Grid>
                          <Grid item lg={3} md={6} sm={4} xs={6}>
                            <div className="template-details mt-15">
                              <img src={WelcomeTemplate} alt="Birthday" />
                              <p>Welcome</p>

                              <Button
                                variant="contained"
                                color="primary"
                                title="Select"
                                className="choose-btn"
                                size="small"
                                onClick={(e) => {
                                  selectTemplate(WelcomeContent, e)
                                }}
                              >
                                Select
                              </Button>
                            </div>
                          </Grid>
                          <Grid item lg={3} md={6} sm={4} xs={6}>
                            <div className="template-details mt-15">
                              <img src={SimpleTemplate} alt="Birthday" />
                              <p>Simple</p>

                              <Button
                                variant="contained"
                                color="primary"
                                title="Select"
                                className="choose-btn"
                                size="small"
                                onClick={(e) =>
                                  selectTemplate(simpleContent, e)
                                }
                              >
                                Select
                              </Button>
                            </div>
                          </Grid>

                          <Grid item lg={3} md={6} sm={4} xs={6}>
                            <div className="template-details mt-15">
                              <img src={NewsLetterTemplate} alt="Birthday" />
                              <p>Newsletter</p>

                              <Button
                                variant="contained"
                                color="primary"
                                title="Select"
                                className="choose-btn"
                                size="small"
                                onClick={(e) =>
                                  selectTemplate(newsLetterContent, e)
                                }
                              >
                                Select
                              </Button>
                            </div>
                          </Grid>
                          <Grid item lg={3} md={6} sm={4} xs={6}>
                            <div className="template-details mt-15">
                              <img src={PromotionTemplate} alt="Birthday" />
                              <p>Promotion</p>

                              <Button
                                variant="contained"
                                color="primary"
                                title="Select"
                                className="choose-btn"
                                size="small"
                                onClick={(e) =>
                                  selectTemplate(promotionTemplateContent, e)
                                }
                              >
                                Select
                              </Button>
                            </div>
                          </Grid>
                        </Grid>
                      </AccordionDetails>
                    </div>

                    {/* content builder start */}
                    <div
                      className="custom-unlayer-editor"
                      style={
                        !chooseTemplate
                          ? { display: 'block' }
                          : { display: 'none' }
                      }
                    >
                      {loading && <Loader />} {/* Loading overlay */}
                      <EmailEditor
                        ref={emailEditorRef}
                        onLoad={() => {
                          console.log('Editor loaded successfully!')
                        }}
                        options={{
                          appearance: { theme: 'light' },
                          tools: {
                            text: { enabled: true },
                            image: {
                              enabled: true,
                              properties: {
                                altText: {
                                  value: 'Image',
                                },
                                maxWidth: 2038,
                                maxHeight: 1365,
                              },
                            },
                            menu: { enabled: false },
                            FirstName: { enabled: true },
                            html: { enabled: false },
                          },
                          customCSS:
                            '.blockbuilder-branding{display: none !important;}',
                          user: {
                            id: loginId,
                          },
                        }}
                        onReady={(unlayer) => {
                          unlayer.setMergeTags({
                            first_name: {
                              name: 'First Name',
                              value: '{{first_name}}',
                            },
                            last_name: {
                              name: 'Last Name',
                              value: '{{last_name}}',
                            },
                          })
                          if (jsonEditor) {
                            unlayer.loadDesign(jsonEditor)
                          } else if (!isEdit && !isView) {
                            unlayer.loadTemplate(565673)
                          }
                          setLoading(false)
                        }}
                        projectId={255562}
                      />
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        marginTop: '10px',
                      }}
                    >
                      <Button
                        className="nav-btn  mt-10"
                        variant="contained"
                        color="primary"
                        title="Back"
                        onClick={handlePrevious}
                      >
                        Back
                      </Button>
                      <Button
                        className="nav-btn"
                        variant="contained"
                        color="primary"
                        title={isView ? 'Next' : 'Save & Next'}
                        onClick={handleNext}
                      >
                        {isView ? 'Next' : 'Save & Next'}
                      </Button>
                    </div>

                    {/* content builder end */}
                  </div>
                }
                {/* campaign content end */}

                {/* campaign review start */}
                {activeStep === 4 && (
                  <div className="campaign-review">
                    <h4 className="title">Ready to Send? </h4>
                    <p className="subtext">
                      Review your campaign and the suggestions below before
                      sending.
                    </p>
                    <ul>
                      <li>
                        <div className="left">
                          <div className="list-title">
                            <img src={OutlineCheck} alt="Checkmark" />
                            <h6>Confirming the trigger</h6>
                          </div>
                          <p>
                            {getTriggerSummary(formik.values.triggerTypeId)}
                          </p>
                        </div>
                        {!isView && (
                          <Button
                            variant="contained"
                            color="primary"
                            size="small"
                            title="Edit"
                            onClick={() => handleEdit(0)}
                          >
                            Edit
                          </Button>
                        )}
                      </li>
                      <li>
                        <div className="left">
                          <div className="list-title">
                            <img src={OutlineCheck} alt="Checkmark" />
                            <h6>Recipients</h6>
                          </div>
                          {formik.values.triggerTypeId !== 6 ? (
                            <>
                              <p>This will send to Email List.</p>
                              <p>{totalEmail} total recipients.</p>
                            </>
                          ) : (
                            <p>No recipients required for this trigger</p>
                          )}
                        </div>
                        {!isView && (
                          <Button
                            variant="contained"
                            color="primary"
                            size="small"
                            title="Edit"
                            onClick={() => handleEdit(1)}
                          >
                            Edit
                          </Button>
                        )}
                      </li>
                      {/* <li>
                      <div className="left">
                        <div className="list-title">
                          <img src={OutlineCheck} alt="Checkmark" />
                          <h6>Smart Sending</h6>
                        </div>
                        <p>Smart sending is enabled for this campaign.</p>
                        <p>
                          It will not be sent to people who received a message
                          in the last 16 hours.
                        </p>
                      </div>
                      <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        title="Edit"
                      >
                        Edit
                      </Button>
                    </li> */}
                      <li>
                        <div className="left">
                          <div className="list-title">
                            <img src={OutlineCheck} alt="Checkmark" />
                            <h6>Subject Line</h6>
                          </div>
                          <p>{formik.values.subjectLine}</p>
                        </div>
                        {!isView && (
                          <Button
                            variant="contained"
                            color="primary"
                            size="small"
                            title="Edit"
                            onClick={() => handleEdit(2)}
                          >
                            Edit
                          </Button>
                        )}
                      </li>
                      <li>
                        <div className="left">
                          <div className="list-title">
                            <img src={OutlineCheck} alt="Checkmark" />
                            <h6>Form & Replies</h6>
                          </div>
                          <p>
                            Your campaign will come from{' '}
                            {formik.values.senderEmail}.
                          </p>
                          <p>
                            All replies will be sent to{' '}
                            {formik.values.senderEmail}.
                          </p>
                        </div>
                        {!isView && (
                          <Button
                            variant="contained"
                            color="primary"
                            size="small"
                            title="Edit"
                            onClick={() => handleEdit(2)}
                          >
                            Edit
                          </Button>
                        )}
                      </li>
                    </ul>
                    {isView ? (
                      <Button
                        className="nav-btn"
                        variant="outlined"
                        color="secondary"
                        type="submit"
                        title="Close"
                      >
                        Close
                      </Button>
                    ) : (
                      <Button
                        className="nav-btn"
                        variant="contained"
                        color="primary"
                        type="submit"
                        title={
                          isEdit
                            ? 'Update the email Campaign'
                            : 'Send the email Campaign'
                        }
                      >
                        {isEdit
                          ? 'Update the email Campaign'
                          : 'Send the email Campaign'}
                      </Button>
                    )}
                  </div>
                )}
                {/* campaign review end */}
                {confirmationDialog && (
                  <Dialog
                    className="primary-dialog alert-dialog"
                    maxWidth="xs"
                    fullWidth
                    open={confirmationDialog}
                  >
                    <DialogTitle>
                      <h5>Save Progress Before Exiting?</h5>
                      <Button
                        variant="text"
                        title="Close"
                        onClick={() => {
                          setConfirmationDialog(false)
                        }}
                        className="icon-btn rounded ml-auto"
                        color="inherit"
                      >
                        <img src={Close} alt="Close" />
                      </Button>
                    </DialogTitle>
                    <DialogContent className="pb-0">
                      <p>
                        If you close this window, your progress will be lost.
                        Would you like to save your work as a draft before
                        exiting?
                      </p>
                    </DialogContent>
                    <DialogActions>
                      <div className="btn-list">
                        <Button
                          variant="contained"
                          color="primary"
                          title="Save as Draft"
                          onClick={() => {
                            handleNext(formik.values, null, true)
                            setConfirmationDialog(false)
                          }}
                        >
                          Save as Draft
                        </Button>
                        <Button
                          title="No"
                          variant="outlined"
                          color="primary"
                          onClick={handleClose}
                        >
                          <span>No</span>
                        </Button>
                      </div>
                    </DialogActions>
                  </Dialog>
                )}

                {openTokenDialog && (
                  <Dialog
                    className="primary-dialog"
                    maxWidth="xs"
                    fullWidth
                    open={openTokenDialog}
                    onClose={() => setOpenTokenDialog(false)}
                  >
                    <DialogTitle>
                      <h5>Verify Sender Email</h5>
                      <Button
                        variant="text"
                        title="Close"
                        onClick={() => setOpenTokenDialog(false)}
                        className="icon-btn rounded"
                        color="inherit"
                      >
                        <img src={Close} alt="Close" />
                      </Button>
                    </DialogTitle>
                    <DialogContent className="pb-0">
                      <div className="form-group">
                        <p style={{ textAlign: 'center' }}>
                          Please check your email, copy and paste the URL link
                          you received in the email from SendGrid below. <br />
                          This is only one time process.
                        </p>
                        <TextField
                          variant="outlined"
                          label="Enter the link here..."
                          multiline
                          rows={5}
                          type="text"
                          onChange={(e) => {
                            setUrlToken(e.target.value)
                            console.log(e.target.value)
                          }}
                        />
                      </div>
                    </DialogContent>
                    <DialogActions>
                      <div className="btn-list">
                        <Button
                          variant="contained"
                          color="primary"
                          title="Submit"
                          onClick={() => verifySenderToken(urlToken)}
                        >
                          Submit
                        </Button>
                      </div>
                    </DialogActions>
                  </Dialog>
                )}
              </div>
            </form>
            {/* stepper body */}
          </div>
          {/* stepper wrapper end */}
        </DialogContent>
      </Dialog>
      {/* create campaign dialog end */}
    </>
  )
}

export default CreateCampaignDialog
