import * as signalR from '@microsoft/signalr'
import {
  Checkbox,
  Collapse,
  FormControlLabel,
  Grid,
  InputAdornment,
  TextField,
} from '@mui/material'
import Aos from 'aos'
import { BackArrow, Delete, PlusWhite } from 'assets/images'
import Button from 'components/Button/Index'
import ChangeConfigurationConfirmationDialog from 'components/ChangeConfigurationConfirmationDialog/Index'
import Datepicker from 'components/Datepicker/Index'
import DeleteConfirmationDialog from 'components/DeleteConfirmationDialog/Index'
import FileUpload from 'components/FileUpload/Index'
import Layout from 'components/Layout/Layout'
import Select from 'components/Select/Index'
import SelectStringId from 'components/Select/selectStringId'
import Switch from 'components/Switch/Index'
import Textfield from 'components/Textfield/Index'
import { DialogOptions } from 'enums/Common'
import {
  establishmentList,
  selectedEstablishment,
  setSelectedEstablishment,
} from 'features/establishment/establishment'
import { useFormik } from 'formik'
import {
  IEstablishment,
  IEstablishmentState,
  IMonthlySubscription,
  IPOSEstablishment,
} from 'interfaces/establishment'
import { RootObject, Table } from 'interfaces/omnivore'
import { IPos } from 'interfaces/restaurant'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import blobService from 'services/blob.service'
import countryService from 'services/country.service'
import currencyService from 'services/currency.service'
import establishmentService from 'services/establishment.service'
import { getTenderTypesFromPOS } from 'services/omnivore-service'
import restaurantService from 'services/restaurant.service'
import {
  EMAIL_REGEX,
  PIXELPOINT_POS_ID,
  SQUIRREL_POS_ID,
  POS_CONFIG_KEYS,
  POS_CONFIG_KEYS_LIST,
  POS_TENDER_TYPE,
  SIGNAL_R_HUB,
  TENDER_TYPE_NAME_OPTION1,
  TENDER_TYPE_NAME_OPTION2,
  TENDER_TYPE_NAME_OPTION3,
} from 'utility/constants'
import { formatPhoneNumber, resolveHTTP } from 'utility/helper'
import * as yup from 'yup'

Aos.init({
  duration: 600,
  once: true,
})
interface ITenderTypeResponse {
  Id: number
}

function AddEditEstablishment() {
  const location = useLocation()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const selectedEstablishmentCurrent = useSelector(selectedEstablishment)

  const [currentId] = useState(
    (location.state as IEstablishmentState)?.establishmentId
  )
  const [currentRestaurantId] = useState(
    (location.state as IEstablishmentState)?.restaurantId
  )
  const estList = useSelector(establishmentList)
  const [isEditMode] = useState(Number.isInteger(currentId))
  const [isFromAddRestaurant] = useState(
    (location.state as IEstablishmentState)?.fromAddRestaurant
  )
  const [PosError, setPosError] = useState(false)
  const [showConfig, setShowConfig] = useState(false)
  const [openChangeConfigurationDialog, setOpenChangeConfigurationDialog] =
    useState(false)
  const [subscriptionStartDate, setSubscriptionStartDate] = useState(null)
  // pos configuration items add/delete
  const [cardNumberAndCard, setCardNumberAndCard] = useState('')
  let posConfigItems: IPOSEstablishment[] = [
    {
      key: 'locationId',
      value: '',
      posEstablishmentConfigurationId: 0,
      readOnly: true,
    },
  ]

  const apiKeyObj: IPOSEstablishment = {
    key: 'Api-Key',
    value: '',
    posEstablishmentConfigurationId: 0,
    readOnly: true,
  }

  const locationEmailObj: IPOSEstablishment = {
    key: 'Location-Email',
    value: '',
    posEstablishmentConfigurationId: 0,
    readOnly: true,
  }

  const [posConfigItemsList, setPosConfigItemsList] =
    useState<IPOSEstablishment[]>(posConfigItems)
  const [restaurantsList, setRestaurantList] = useState([])
  const [currentPosId, setCurrentPosId] = useState<number>()
  const [selectedRestaurantId, setSelectedRestaurantId] = useState<number>(null)
  const [stateList, setStateList] = useState([])
  const [filteredStateList, setFilteredStateList] = useState([])
  const [countryList, setCountryList] = useState([])
  const [currencyList, setCurrencyList] = useState([])

  const [stripeCustomerList, setStripeCustomerList] = useState([])
  const [file, setFile] = useState<File>(null)
  const [name, setName] = useState('')
  const [token, setToken] = useState(null)
  const [storgaeName, setStorageName] = useState(null)
  const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState(false)
  const [establishmentIdForDelete, setestablishmentIdForDelete] = useState(0)
  const [openChangeConfigurationMessage, setOpenChangeConfigurationMessage] =
    useState('')
  const [omnivorePosNames, setOmnivorePosName] = useState('')
  const [priceIdList, setPriceIdList] = useState([])
  const currRestaurantId = currentRestaurantId || selectedRestaurantId
  const currentRestaurantIndex = estList.findIndex(
    (d) => d.restaurantId === currRestaurantId
  )
  const currentRestaurantData = estList[currentRestaurantIndex]
  const [timeZoneList, setTimeZoneList] = useState([])
  const [isSubscriptionActiveState, setIsSubscriptionActiveState] =
    useState(false)
  const [posList, setPosList] = useState<IPos[]>([])
  const [showGuestConfig, setShowGuestConfig] = useState<boolean>(false)

  const generateArrayFromCommaSeperatedStrings = (val: string) => {
    return val.split(',')
  }
  const getOmnivorePosNames = async () => {
    const omnivorePoss = await restaurantService.GetOmnivorePosName()
    setOmnivorePosName(omnivorePoss.data.data.omnivorePOSs)
  }

  const getCountryList = useCallback(
    async () => {
      const data = await countryService.getCountries()
      setCountryList(data.data.data)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  const getStateList = useCallback(
    async () => {
      const data = await establishmentService.getAllStates()
      setStateList(data.data.data)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  useEffect(
    () => {
      getCountryList()
      getStateList()
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  const getTimeZoneList = useCallback(
    async () => {
      try {
        const data = await restaurantService.getTimeZone()
        setTimeZoneList(data.data.data.data)
        // dispatch(setRestaurants(data.data.data.data))
      } catch (e) {
        console.log(e)
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  const getPosData = async () => {
    if (posList && !posList.length) {
      const data = await restaurantService.getPosList()
      setPosList(data.data.data)
    }
  }

  useEffect(() => {
    getOmnivorePosNames()
    getTimeZoneList()
    getPosData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleSave = async (data: IEstablishment) => {
    let isError = false
    const containerName = process.env.REACT_APP_AZURE_PUBLIC_CONTAINER
    // upload file logic
    if (file) {
      const fileName = file.name
      await blobService.uploadFileToBlob(
        file,
        fileName,
        containerName,
        storgaeName || '',
        token || ''
      )
      data.businessLogoPath = `${containerName}/${fileName}`
    } else if (data?.businessLogoPath?.includes('?')) {
      const mainUrl = data.businessLogoPath.split('?')[0]
      const path = mainUrl.split(containerName)[1]
      data.businessLogoPath = `${containerName}${path}`
    }
    const posConfigKeyList: string[] = []
    posConfigItemsList.forEach((element) => {
      posConfigKeyList.push(element?.key.toLowerCase())
    })
    const duplicateConfigs = posConfigKeyList.filter(
      (item, index) => posConfigKeyList.indexOf(item.toLowerCase()) !== index
    )
    if (duplicateConfigs.length > 0) {
      toast.error('Config keys should be unique')
      return
    }
    posConfigItemsList.forEach((element) => {
      const configKeyRow = document.getElementById(element?.key)
      if (element?.value === '' || element?.value === null) {
        isError = true
        configKeyRow.innerHTML = `${element.key} is required!`
      } else {
        configKeyRow.innerHTML = ''
      }
      if (element?.key.toLowerCase() === 'location-email' && element?.value) {
        const arrayOfEmails = generateArrayFromCommaSeperatedStrings(
          element?.value
        )
        arrayOfEmails.forEach((email) => {
          if (!email.trim().match(EMAIL_REGEX)) {
            console.log(email)
            isError = true
            configKeyRow.innerHTML = `Please enter valid email address!`
          }
        })
      }
    })
    if (isError) return
    const mappedPosData = posConfigItemsList.filter(
      (d) => !(d.key === '' && d.value === '')
    )
    const isLocationIdAvailable = mappedPosData.some(
      (d) =>
        d.key.trim().toLowerCase() ===
        POS_CONFIG_KEYS.LOCATION_ID.trim().toLowerCase()
    )
    if (
      !isLocationIdAvailable &&
      (currentRestaurantData.posId === PIXELPOINT_POS_ID ||
        currentRestaurantData.posId === SQUIRREL_POS_ID)
    ) {
      setPosError(true)
      return
    }
    setPosError(false)
    data.posConfigs = data.posConfigs.filter(
      (d) => d.key !== '' && d.value !== ''
    )
    data.stripeMonthlySubscription.subscriptionStartDate = new Date(
      data.stripeMonthlySubscription.subscriptionStartDate
    ).toDateString()

    let isResError = false
    if (!isEditMode) {
      const [result] = await resolveHTTP(
        establishmentService.creatEstablishment(data)
      )
      if (result) toast.success('Location Created Successfully')
      else isResError = true
    } else {
      if (name && name.toLowerCase() !== 'nofile' && !file) {
        data.businessLogoPath = null
        await blobService.deleteFile(name, containerName, storgaeName, token)
        toast.success('File deleted successfully')
      }
      const [result] = await resolveHTTP(
        establishmentService.updateEstablishment(data)
      )
      if (result) toast.success('Location Updated Successfully')
      else isResError = true
    }
    if (!isResError) {
      if (isFromAddRestaurant) {
        navigate('/restaurant/locations')
      } else {
        navigate('/locations')
      }
    }
  }

  const weekList = [
    { key: 'Monday', value: 6 },
    { key: 'Tuesday', value: 5 },
    { key: 'Wednesday', value: 4 },
    { key: 'Thursday', value: 3 },
    { key: 'Friday', value: 2 },
    { key: 'Saturday', value: 1 },
    { key: 'Sunday', value: 0 },
  ]

  const noOfGuestList = [
    { key: '2', value: 2 },
    { key: '3', value: 3 },
    { key: '4', value: 4 },
    { key: '5', value: 5 },
  ]

  const validationSchema = useMemo(
    () =>
      yup.object().shape({
        addressLine1: yup.string().required('Address Line 1 is required.'),
        cityName: yup.string().required('City is required'),
        isShowGoogleReview: yup.boolean(),
        googleLocationId: yup.string().when('isShowGoogleReview', {
          is: true,
          then: yup.string().required('Google Location Id is required'),
        }),
        restaurantId: yup.number().required('Restaurant is required'),
        establishmentName: yup.string().required('Location Name is required'),
        countryId: yup.number().required('Country is required'),
        stateId: yup.number().required('Province is required'),
        currencyId: yup.number().required('Currency is required'),
        postalCode: yup
          .string()
          .required('Postal code is required')
          .matches(/^[A-Za-z0-9\s-]{3,10}$/, 'Postal code is not valid'),
        phoneNumber: yup
          .string()
          .required('Phone number is required')
          .matches(
            /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/,
            'Phone number is not valid'
          ),
        transactionPercentage: yup
          .number()
          .typeError('Please Enter Number')
          .required('Interchange fee is required'),
        transactionFee: yup
          .number()
          .typeError('Please Enter Number')
          .required('Transaction fee is required'),
        isSubscriptionActive: yup.boolean(),
        isLocationActive: yup.boolean(),
        stripeMonthlySubscription: yup.object().when('isSubscriptionActive', {
          is: true,
          then: yup.object().shape({
            priceId: yup.string().required('Please select plan'),
            restOwnerEmail: yup
              .string()
              .required('Email is required')
              .matches(EMAIL_REGEX, 'Email is not valid'),
            restOwnerFirstName: yup.string().required('First Name is required'),
            restOwnerLastName: yup.string().required('Last Name is required'),
          }),
        }),
        timeZoneId: yup.number().required('TimeZone is required'),
        posId: yup.number().required('POS is required'),
        emailAddress: yup
          .string()
          .required('Email is required')
          .matches(EMAIL_REGEX, 'Email is not valid'),
        tipOne: yup
          .number()
          .typeError('Tip configuration is required')
          .max(100, `Please enter between 0 and 100`),
        tipTwo: yup
          .number()
          .typeError('Tip configuration is required')
          .max(100, `Please enter between 0 and 100`),
        tipThree: yup
          .number()
          .typeError('Tip configuration is required')
          .max(100, `Please enter between 0 and 100`),
        maxNoOfGuest: yup.number().when('isSplitByGuest', {
          is: true,
          then: yup.number().required('Please select Max. no of guests'),
          otherwise: yup.number().nullable(),
        }),
      }),
    []
  )

  const convertUTCDateToLocalDate = (date) => {
    const newDate = new Date(
      new Date(date).getTime() + new Date(date).getTimezoneOffset() * 60 * 1000
    )
    newDate.setHours(0, 0, 0, 0)
    return newDate
  }

  const value1: IMonthlySubscription = {
    customerId: '',
    isExistingCustomer: false,
    priceId: '',
    restOwnerEmail: '',
    restOwnerFirstName: '',
    restOwnerLastName: '',
    subscriptionStartDate:
      subscriptionStartDate || convertUTCDateToLocalDate(new Date()),
  }
  const formik = useFormik<IEstablishment>({
    initialValues: {
      restaurantId: currentRestaurantId || null,
      addressLine1: '',
      addressLine2: '',
      cityName: '',
      postalCode: '',
      stateId: null,
      phoneNumber: '',
      establishmentName: '',
      posConfigs: [],
      establishmentId: currentId,
      webhook: true,
      paymentApi: true,
      tableApi: true,
      agentVersion: '',
      stationInfo: '',
      googleLocationId: '',
      isShowGoogleReview: false,
      isShowReview: false,
      IsInternalReview: false,
      transactionFee: null,
      transactionPercentage: null,
      isSubscriptionActive: false,
      isLocationActive: false,
      stripeMonthlySubscription: value1 || null,
      timeZoneId: null,
      payerId: '',
      payeeName: '',
      emailAddress: '',
      businessLogoPath: '',
      tipOne: null,
      tipTwo: null,
      tipThree: null,
      disbursementWeekStart: 6,
      disbursementWeekEnd: 0,
      posId: null,
      countryId: null,
      currencyId: null,
      stripeConnectAccountId: '',
      isSplitByGuest: false,
      maxNoOfGuest: null,
      guestAutoOptIn: false,
      establishmentWebsiteURL: '',
    },
    validationSchema,
    // validateOnBlur: true,
    onSubmit: (values) => {
      handleSave(values)
    },
  })

  const getCustomerDetails = async (
    cusId: string,
    onlyCardDetails: boolean
  ) => {
    const data = await establishmentService.GetStripeCustomerDetails({
      customerId: cusId,
    })
    if (!onlyCardDetails) {
      formik.setFieldValue(
        'stripeMonthlySubscription.priceId',
        data.data.data.priceId
      )
      formik.setFieldValue(
        'stripeMonthlySubscription.restOwnerEmail',
        data.data.data.restOwnerEmail
      )
      formik.setFieldValue(
        'stripeMonthlySubscription.restOwnerFirstName',
        data.data.data.restOwnerFirstName
      )
      formik.setFieldValue(
        'stripeMonthlySubscription.restOwnerLastName',
        data.data.data.restOwnerLastName
      )
    }
    setCardNumberAndCard(
      `${data.data.data.cardNumber} (${data.data.data.cardType})`
    )
  }

  useEffect(
    () => {
      const filteredStates = stateList.filter(
        (state) => state.countryId === formik.values.countryId
      )
      setFilteredStateList(filteredStates)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [formik.values?.countryId, stateList]
  )
  const handlePosChange = (posId: number) => {
    const selectedPos = posList.find((pos) => pos.posId === posId)
    if (selectedPos) {
      if (!selectedPos.splitByGuest) {
        formik.setFieldValue('isSplitByGuest', false)
        formik.setFieldValue('maxNoOfGuest', null)
      }
      setShowGuestConfig(selectedPos.splitByGuest)
    }
  }
  const getEstablishmentDetail = useCallback(
    async (establishmentId: number) => {
      try {
        const data = await establishmentService.getEstablishmentById({
          establishmentId,
        })
        const EstablishmentDetailData = data.data.data
        formik.setValues(EstablishmentDetailData)
        setIsSubscriptionActiveState(
          EstablishmentDetailData.isSubscriptionActive
        )
        setCurrentPosId(EstablishmentDetailData?.posId)
        formik.setFieldValue('restaurantId', currentRestaurantId)
        formik.setFieldValue('webhook', EstablishmentDetailData.webhook)
        formik.setFieldValue('paymentApi', EstablishmentDetailData.paymentApi)
        formik.setFieldValue('tableApi', EstablishmentDetailData.tableApi)
        formik.setFieldValue('posId', EstablishmentDetailData.posId)
        formik.setFieldValue(
          'transactionPercentage',
          EstablishmentDetailData.transactionPercentage
        )
        formik.setFieldValue(
          'transactionFee',
          EstablishmentDetailData.transactionFee
        )
        formik.setFieldValue(
          'isShowReview',
          EstablishmentDetailData.isShowReview
        )
        formik.setFieldValue(
          'isShowGoogleReview',
          EstablishmentDetailData.isShowGoogleReview
        )
        formik.setFieldValue(
          'phoneNumber',
          formatPhoneNumber(EstablishmentDetailData.phoneNumber)
        )
        formik.setFieldValue('stationInfo', EstablishmentDetailData.stationInfo)
        formik.setFieldValue(
          'googleLocationId',
          EstablishmentDetailData?.googleLocationId || ''
        )
        formik.setFieldValue(
          'agentVersion',
          EstablishmentDetailData.agentVersion
        )
        formik.setFieldValue(
          'isSubscriptionActive',
          EstablishmentDetailData.isSubscriptionActive
        )
        formik.setFieldValue(
          'isSplitByGuest',
          EstablishmentDetailData.isSplitByGuest
        )
        formik.setFieldValue(
          'maxNoOfGuest',
          EstablishmentDetailData.maxNoOfGuest
        )
        formik.setFieldValue(
          'guestAutoOptIn',
          EstablishmentDetailData.guestAutoOptIn
        )

        if (EstablishmentDetailData.isSplitByGuest) {
          setShowGuestConfig(true)
        }
        if (EstablishmentDetailData.isSubscriptionActive) {
          formik.setFieldValue(
            'isLocationActive',
            EstablishmentDetailData.isLocationActive
          )
          formik.setFieldValue(
            'stripeMonthlySubscription',
            EstablishmentDetailData.stripeMonthlySubscription
          )
          formik.setFieldValue(
            'stripeMonthlySubscription.priceId',
            EstablishmentDetailData.stripeMonthlySubscription?.priceId
          )
          setSubscriptionStartDate(
            EstablishmentDetailData.stripeMonthlySubscription
              .subscriptionStartDate
          )
          getCustomerDetails(
            EstablishmentDetailData.stripeMonthlySubscription.customerId,
            true
          )
        } else {
          setSubscriptionStartDate(convertUTCDateToLocalDate(new Date()))
          formik.setFieldValue(
            'stripeMonthlySubscription.subscriptionStartDate',
            convertUTCDateToLocalDate(new Date())
          )
        }

        formik.setFieldValue(
          'timeZoneId',
          EstablishmentDetailData.timeZoneId < 1 ||
            EstablishmentDetailData.timeZoneId === null
            ? null
            : EstablishmentDetailData.timeZoneId
        )
        if (
          currentPosId === PIXELPOINT_POS_ID ||
          currentPosId === SQUIRREL_POS_ID
        ) {
          setShowConfig(true)
        } else if (
          EstablishmentDetailData.posConfigs.some(
            (e) => e.key === POS_CONFIG_KEYS.LOCATION_ID
          ) &&
          EstablishmentDetailData.posConfigs.some(
            (e) => e.key === POS_CONFIG_KEYS.API_KEY
          )
        ) {
          setShowConfig(true)
        } else {
          setShowConfig(false)
        }
        EstablishmentDetailData.posConfigs.forEach((element) => {
          if (POS_CONFIG_KEYS_LIST.includes(element.key)) {
            element.readOnly = true
          }
        })
        let isApiKeyAvailable = false
        posConfigItems.forEach((element) => {
          let isPropertyMatch = false
          EstablishmentDetailData.posConfigs.forEach((ele) => {
            if (element?.key === ele?.key) {
              isPropertyMatch = true
            }
            if (ele?.key.toLowerCase() === 'api-key') isApiKeyAvailable = true
          })
          if (!isPropertyMatch) {
            EstablishmentDetailData.posConfigs.push(element)
          }
        })
        const currentPos = estList.find(
          (x) => x.restaurantId === currentRestaurantId
        )?.posId
        if (
          (currentPos === PIXELPOINT_POS_ID ||
            currentPos === SQUIRREL_POS_ID) &&
          !EstablishmentDetailData.posConfigs.some(
            (d) => d.key === 'Location-Email'
          )
        ) {
          EstablishmentDetailData.posConfigs.push(locationEmailObj)
        }
        if (
          currentPos !== PIXELPOINT_POS_ID &&
          currentPos !== SQUIRREL_POS_ID &&
          !isApiKeyAvailable
        ) {
          EstablishmentDetailData.posConfigs.push(apiKeyObj)
        }
        setPosConfigItemsList(EstablishmentDetailData.posConfigs)
      } catch (e) {
        console.log(e)
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [formik, currentPosId]
  )

  const handleFileChange = (fileData: File) => {
    setFile(fileData)
  }

  const pullName = (data: string) => {
    setName(data)
    setFile(null)
    formik.setFieldValue('businessLogoPath', null)
  }

  const getSasToken = async () => {
    if (!token) {
      const azureData = await blobService.getAzureDetails()
      setToken(azureData.data.data.sasToken)
      setStorageName(azureData.data.data.azureStorageAccountName)
    }
  }

  const closeConfigurationConfirmationDialog = () => {
    setOpenChangeConfigurationDialog(false)
  }
  useEffect(() => {
    if (Number.isInteger(+currentId) && +currentId > 0) {
      getEstablishmentDetail(Number(currentId))
    }
    getSasToken()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const currentPos = estList.find(
      (x) => x.restaurantId === selectedRestaurantId
    )?.posId
    const currentPosName = estList.find(
      (x) => x.restaurantId === selectedRestaurantId
    )?.posName
    if (
      currentPosName &&
      omnivorePosNames.split(',').includes(currentPosName.toLocaleLowerCase())
    ) {
      posConfigItems.push(apiKeyObj)
    } else {
      const apiKeyIndex = posConfigItems.findIndex((d) => d.key === 'Api-Key')
      if (apiKeyIndex !== -1) posConfigItems.splice(apiKeyIndex, 1)
    }
    if (currentPos === PIXELPOINT_POS_ID || currentPos === SQUIRREL_POS_ID) {
      posConfigItems.push(locationEmailObj)
    } else {
      const locationEmailIndex = posConfigItems.findIndex(
        (d) => d.key === 'Location-Email'
      )
      if (locationEmailIndex !== -1)
        posConfigItems.splice(locationEmailIndex, 1)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    posConfigItems = [...posConfigItems]
    setPosConfigItemsList(posConfigItems)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRestaurantId])

  useEffect(() => {
    // get current rest's pos
    if (restaurantsList.length > 0) {
      const currentPos = restaurantsList.find(
        (x) => x.restaurantId === currentRestaurantId
      )
      setCurrentPosId(currentPos?.posId)
    }
  }, [currentRestaurantId, restaurantsList])

  const addPosConfigItem = () => {
    const newPosition = {
      key: '',
      value: '',
      posEstablishmentConfigurationId: 0,
    }
    const newPosConfigItems = posConfigItemsList
    newPosConfigItems.push(newPosition)
    setPosConfigItemsList([...newPosConfigItems])
    if (
      currentPosId === PIXELPOINT_POS_ID ||
      currentPosId === SQUIRREL_POS_ID
    ) {
      setShowConfig(true)
    } else if (
      newPosConfigItems.some((e) => e.key === POS_CONFIG_KEYS.LOCATION_ID) &&
      newPosConfigItems.some((e) => e.key === POS_CONFIG_KEYS.API_KEY)
    ) {
      setShowConfig(true)
    } else {
      setShowConfig(false)
    }
    formik.setFieldValue('posConfigs', posConfigItemsList)
  }

  const addConfigField = (tenderTypeId: number) => {
    const tenderTypeIndex = posConfigItemsList.findIndex(
      (d) => d.key === POS_TENDER_TYPE
    )
    if (tenderTypeIndex === -1) {
      const newPosition = {
        key: POS_TENDER_TYPE,
        value: tenderTypeId.toString(),
        posEstablishmentConfigurationId: 0,
        readOnly: true,
      }

      const newPosConfigItems = posConfigItemsList
      newPosConfigItems.push(newPosition)
      setPosConfigItemsList([...newPosConfigItems])
      formik.setFieldValue('posConfigs', posConfigItemsList)
    } else {
      const allConfigs = posConfigItemsList
      const row = allConfigs[tenderTypeIndex]
      row.value = tenderTypeId.toString()
      allConfigs[tenderTypeIndex] = row
      setPosConfigItemsList([...allConfigs])
    }
  }

  const getTenderTypes = async () => {
    const enteredLocationId = posConfigItemsList.find(
      (d) => d.key.toLowerCase() === 'locationid'
    )?.value
    if (
      !showConfig &&
      currentRestaurantData?.posId !== PIXELPOINT_POS_ID &&
      currentRestaurantData?.posId !== SQUIRREL_POS_ID
    ) {
      setOpenChangeConfigurationDialog(true)
      setOpenChangeConfigurationMessage(
        'Please add LocationId and Api-key in POS configuration section.'
      )
      return
    }
    if (!enteredLocationId) {
      setOpenChangeConfigurationDialog(true)
      setOpenChangeConfigurationMessage(
        'Please add LocationId in POS configuration section.'
      )
      return
    }
    if (
      currentRestaurantData?.posId === PIXELPOINT_POS_ID ||
      currentRestaurantData?.posId === SQUIRREL_POS_ID
    ) {
      const result = await establishmentService.CheckIsAgentAvailable({
        locationId: enteredLocationId.toString(),
      })
      if (!result.data.data.locationId) {
        toast.error('Agent is not connected')
      }
    }

    if (!selectedRestaurantId && !isEditMode) {
      setOpenChangeConfigurationDialog(true)
      setOpenChangeConfigurationMessage('Please select Restaurant')
      return
    }
    if (currentRestaurantData) {
      if (
        currentRestaurantData?.posId === PIXELPOINT_POS_ID ||
        currentRestaurantData?.posId === SQUIRREL_POS_ID
      ) {
        const hubConnection = new signalR.HubConnectionBuilder()
          .withUrl(
            `${process.env.REACT_APP_API_SERVER}/${SIGNAL_R_HUB.HUB_NAME}`
          )
          .configureLogging(signalR.LogLevel.Information)
          .withAutomaticReconnect([0, 1000])
          .build()

        hubConnection
          .start()
          .then(async () => {
            if (hubConnection.connectionId) {
              hubConnection.invoke(
                SIGNAL_R_HUB.SYNC_PAYMENT_METHOD_LIST_FOR_PIXEL,
                '',
                enteredLocationId?.toString()
              )
              hubConnection.on(
                SIGNAL_R_HUB.ON_RECEIVE_PAYMENT_METHOD_LIST,
                async (response) => {
                  const tenderTypeIdResponse: ITenderTypeResponse = JSON.parse(
                    response
                  ) as ITenderTypeResponse

                  // add config boxes
                  addConfigField(tenderTypeIdResponse.Id)
                }
              )
            }
          })
          .catch(() => {
            toast.error('Failed to retreive tender type.')
          })
      } else {
        const locationId = posConfigItemsList.find(
          (item) =>
            item.key.trim().toLowerCase() ===
            POS_CONFIG_KEYS.LOCATION_ID.trim().toLowerCase()
        )
        const apiKey = posConfigItemsList.find(
          (item) =>
            item.key.trim().toLowerCase() ===
            POS_CONFIG_KEYS.API_KEY.trim().toLowerCase()
        )
        let responseData
        try {
          responseData = await getTenderTypesFromPOS(
            apiKey.value,
            locationId.value
          )
        } catch (e) {
          console.log(e)
        }

        const rootObject: RootObject = responseData.data
        let fasTabData: Table
        let fasTabKey
        // eslint-disable-next-line no-underscore-dangle
        rootObject._embedded.tender_types.map((obj) => {
          if (
            obj.name.toLowerCase() === TENDER_TYPE_NAME_OPTION2.toLowerCase() ||
            obj.name.toLowerCase() === TENDER_TYPE_NAME_OPTION1.toLowerCase() ||
            obj.name.toLowerCase() === TENDER_TYPE_NAME_OPTION3.toLowerCase()
          ) {
            fasTabData = obj
            fasTabKey = obj.id
          }
          return fasTabData
        })

        // add config boxes
        addConfigField(fasTabKey)
      }
    }
  }

  const removePosConfigItem = (i: number) => {
    if (posConfigItemsList.length > 1) {
      const updatedPosConfigItems = posConfigItemsList
      updatedPosConfigItems.splice(i, 1)
      setPosConfigItemsList([...updatedPosConfigItems])
      if (
        currentPosId === PIXELPOINT_POS_ID ||
        currentPosId === SQUIRREL_POS_ID
      ) {
        setShowConfig(true)
      } else if (
        updatedPosConfigItems.some(
          (e) => e.key === POS_CONFIG_KEYS.LOCATION_ID
        ) &&
        updatedPosConfigItems.some((e) => e.key === POS_CONFIG_KEYS.API_KEY)
      ) {
        setShowConfig(true)
      } else {
        setShowConfig(false)
      }
      formik.setFieldValue('posConfigs', posConfigItemsList)
    }
  }

  const getRestaurantList = useCallback(
    async (
      pageNo: 1,
      pageSize: 20,
      sortOrder: [{ field: 'name'; sort: 'asc' }]
    ) => {
      try {
        const data = await restaurantService.getAllResturants({
          pageNo,
          pageSize,
          sortGridModels: sortOrder.map((d) => {
            return {
              field: d.field,
              sort: d.sort === 'asc' ? 1 : 0,
            }
          }),
        })
        setRestaurantList(data.data.data.data)
      } catch (e) {
        console.log(e)
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  const getCurrencyList = useCallback(
    async () => {
      const data = await currencyService.getCurrencies()
      setCurrencyList(data.data.data)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  const getStripeCustomer = useCallback(
    async () => {
      const data = await establishmentService.getAllStripeCustomer()
      const customerList = data.data.data.customers.map((e) => {
        return {
          key: e.name,
          value: e.customerId,
        }
      })
      setStripeCustomerList(customerList)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  // getting PriceIds
  const getPriceIdList = useCallback(
    async () => {
      try {
        const data = await establishmentService.getSubscriptionPlan()
        const list = data.data.data.prices
          .filter(
            (e) =>
              e.lookupKey !== null &&
              e.lookupKey !== '' &&
              e.unitAmount !== 0 &&
              e.interval !== null &&
              e.interval !== ''
          )
          .map((e) => {
            return {
              key: `${e.lookupKey} $${e.unitAmount / 100}/${e.interval} `,
              value: e.id,
            }
          })
        setPriceIdList(list)
      } catch (e) {
        console.log(e)
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )
  const toggleDeleteConfirmationDialog = useCallback(
    async (isOpen: boolean, isSubmit = false, id: number) => {
      setOpenDeleteConfirmation(isOpen)
      setestablishmentIdForDelete(id)
      if (isSubmit && !isOpen && id > 0) {
        const [result] = await resolveHTTP(
          establishmentService.deleteEstablishment({
            establishmentId: Number(currentId),
          })
        )
        if (result) {
          if (currentId === selectedEstablishmentCurrent) {
            dispatch(setSelectedEstablishment({ selectedEstablishment: null }))
          }
          toast.success('Location deleted successfully')
          if (isFromAddRestaurant) {
            navigate('/restaurant/locations')
          } else {
            navigate('/locations')
          }
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  const onPosChange = (
    i: number,
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    const allConfigs = posConfigItemsList
    const row = allConfigs[i]
    if (e.target.name === 'key') {
      row.key = e.target.value
    } else {
      row.value = e.target.value
    }
    allConfigs[i] = row
    setPosConfigItemsList([...allConfigs])
    if (
      currentPosId === PIXELPOINT_POS_ID ||
      currentPosId === SQUIRREL_POS_ID
    ) {
      setShowConfig(true)
    } else if (
      allConfigs.some(
        (el) => el.key === POS_CONFIG_KEYS.LOCATION_ID && el.value !== ''
      ) &&
      allConfigs.some(
        (el) => el.key === POS_CONFIG_KEYS.API_KEY && el.value !== ''
      )
    ) {
      setShowConfig(true)
    } else {
      setShowConfig(false)
    }
    formik.setFieldValue('posConfigs', [...allConfigs])
  }

  useEffect(() => {
    getRestaurantList(1, 20, [{ field: 'name', sort: 'asc' }])
    getCurrencyList()
    getPriceIdList()
    getStripeCustomer()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <Layout
        title={isEditMode ? 'Edit  Location | FasTab' : 'New Location | FasTab'}
      >
        {/* page-top start */}
        <div className="page-top">
          <h3 className="page-title">{isEditMode ? 'Edit' : 'New'} Location</h3>
          <div className="right">
            <Button
              variant="contained"
              color="primary"
              title="Back"
              isTypeLink
              to={isFromAddRestaurant ? '/restaurant/locations' : '/locations'}
              size="small"
            >
              <img src={BackArrow} alt="Back" />
              <span>Back</span>
            </Button>
          </div>
        </div>
        {/* page-top end */}
        <div className="card mb-30" data-aos="fade-up">
          <form onSubmit={formik.handleSubmit}>
            <Grid container spacing={6}>
              <Grid item lg={8} xs={12}>
                <Grid container spacing={6}>
                  <Grid item sm={6} xs={12}>
                    <div className="form-group">
                      <Select
                        label="Restaurant"
                        showError={
                          !!formik.errors.restaurantId &&
                          formik.touched.restaurantId
                        }
                        errorText={
                          !!formik.errors.restaurantId &&
                          formik.touched.restaurantId
                            ? 'Restaurant is requied'
                            : ''
                        }
                        disabled={isEditMode || currentRestaurantId !== null}
                        handleSelectValue={(value) => {
                          formik.setFieldValue('restaurantId', value)
                          setSelectedRestaurantId(value)
                        }}
                        formikValue={formik.values?.restaurantId}
                        items={restaurantsList.map((d) => {
                          return {
                            key: d.name,
                            value: d.restaurantId,
                          }
                        })}
                      />
                    </div>
                  </Grid>
                  <Grid item sm={6} xs={12}>
                    <div className="form-group">
                      <Textfield
                        variant="outlined"
                        type="text"
                        label="Location Name"
                        name="establishmentName"
                        handleBlur={formik.handleBlur}
                        value={formik.values?.establishmentName}
                        onChange={formik.handleChange}
                        error={
                          !!formik.errors.establishmentName &&
                          formik.touched.establishmentName
                        }
                        helperText={
                          !!formik.errors.establishmentName &&
                          formik.touched.establishmentName
                            ? formik.errors.establishmentName
                            : ''
                        }
                      />
                    </div>
                  </Grid>
                  <Grid item sm={4} xs={12}>
                    <div className="form-group">
                      <Textfield
                        variant="outlined"
                        label="Email"
                        name="emailAddress"
                        value={formik.values?.emailAddress}
                        onChange={formik.handleChange}
                        error={
                          !!formik.errors.emailAddress &&
                          formik.touched.emailAddress
                        }
                        helperText={
                          !!formik.errors.emailAddress &&
                          formik.touched.emailAddress
                            ? formik.errors.emailAddress
                            : ''
                        }
                        handleBlur={formik.handleBlur}
                      />
                    </div>
                  </Grid>
                  <Grid item sm={4} xs={12}>
                    <div className="form-group">
                      <Textfield
                        variant="outlined"
                        label="Website URL"
                        name="establishmentWebsiteURL"
                        value={formik.values?.establishmentWebsiteURL}
                        onChange={formik.handleChange}
                        error={
                          !!formik.errors.establishmentWebsiteURL &&
                          formik.touched.establishmentWebsiteURL
                        }
                        helperText={
                          !!formik.errors.establishmentWebsiteURL &&
                          formik.touched.establishmentWebsiteURL
                            ? formik.errors.establishmentWebsiteURL
                            : ''
                        }
                        handleBlur={formik.handleBlur}
                      />
                    </div>
                  </Grid>
                  <Grid item sm={4} xs={12}>
                    <div className="form-group">
                      <Textfield
                        variant="outlined"
                        type="text"
                        label="Address Line 1"
                        name="addressLine1"
                        handleBlur={formik.handleBlur}
                        value={formik.values?.addressLine1}
                        onChange={formik.handleChange}
                        error={
                          !!formik.errors.addressLine1 &&
                          formik.touched.addressLine1
                        }
                        helperText={
                          !!formik.errors.addressLine1 &&
                          formik.touched.addressLine1
                            ? formik.errors.addressLine1
                            : ''
                        }
                      />
                    </div>
                  </Grid>
                  <Grid item sm={4} xs={12}>
                    <div className="form-group">
                      <Textfield
                        variant="outlined"
                        type="text"
                        label="Address Line 2"
                        name="addressLine2"
                        value={formik.values?.addressLine2}
                        onChange={formik.handleChange}
                      />
                    </div>
                  </Grid>
                  <Grid item sm={4} xs={12}>
                    <div className="form-group">
                      <Textfield
                        variant="outlined"
                        type="text"
                        label="City"
                        name="cityName"
                        handleBlur={formik.handleBlur}
                        value={formik.values?.cityName}
                        onChange={formik.handleChange}
                        error={
                          !!formik.errors.cityName && formik.touched.cityName
                        }
                        helperText={
                          !!formik.errors.cityName && formik.touched.cityName
                            ? formik.errors.cityName
                            : ''
                        }
                      />
                    </div>
                  </Grid>
                  <Grid item sm={4} xs={12}>
                    <div className="form-group">
                      <Select
                        name="timeZoneId"
                        formikValue={formik.values?.timeZoneId}
                        showError={
                          !!formik.errors.timeZoneId &&
                          formik.touched.timeZoneId
                        }
                        errorText={
                          !!formik.errors.timeZoneId &&
                          formik.touched.timeZoneId
                            ? 'Time Zone is requied'
                            : ''
                        }
                        handleSelectValue={(value) => {
                          formik.setFieldValue('timeZoneId', value)
                        }}
                        label="Time Zone"
                        items={timeZoneList.map((d) => {
                          return {
                            key: d.name,
                            value: d.timeZoneId,
                          }
                        })}
                      />
                    </div>
                  </Grid>
                  <Grid item sm={4} xs={12}>
                    <div className="form-group">
                      <Textfield
                        variant="outlined"
                        type="text"
                        label="Postal Code"
                        name="postalCode"
                        handleBlur={formik.handleBlur}
                        value={formik.values?.postalCode}
                        onChange={formik.handleChange}
                        error={
                          !!formik.errors.postalCode &&
                          formik.touched.postalCode
                        }
                        helperText={
                          !!formik.errors.postalCode &&
                          formik.touched.postalCode
                            ? formik.errors.postalCode
                            : ''
                        }
                      />
                    </div>
                  </Grid>
                  <Grid item sm={4} xs={12}>
                    <div className="form-group">
                      <Select
                        label="Country"
                        name="countryId"
                        showError={
                          !!formik.errors.countryId && formik.touched.countryId
                        }
                        errorText={
                          !!formik.errors.countryId && formik.touched.countryId
                            ? 'Country is requied'
                            : ''
                        }
                        formikValue={formik.values?.countryId}
                        handleSelectValue={(value) => {
                          formik.setFieldValue('countryId', value)
                          const filteredStates = stateList.filter(
                            (state) => state.countryId === value
                          )
                          setFilteredStateList(filteredStates)
                        }}
                        items={countryList.map((d) => {
                          return {
                            key: d.countryName,
                            value: d.countryId,
                          }
                        })}
                      />
                    </div>
                  </Grid>
                  <Grid item sm={4} xs={12}>
                    <div className="form-group">
                      <Select
                        label="State"
                        name="stateId"
                        showError={
                          !!formik.errors.stateId && formik.touched.stateId
                        }
                        errorText={
                          !!formik.errors.stateId && formik.touched.stateId
                            ? 'State is requied'
                            : ''
                        }
                        formikValue={formik.values?.stateId}
                        handleSelectValue={(value) => {
                          formik.setFieldValue('stateId', value)
                        }}
                        items={filteredStateList?.map((d) => {
                          return {
                            key: d.name,
                            value: d.stateId,
                          }
                        })}
                      />
                    </div>
                  </Grid>
                  <Grid item sm={4} xs={12}>
                    <div className="form-group">
                      <Textfield
                        variant="outlined"
                        type="text"
                        label="Phone Number"
                        name="phoneNumber"
                        handleBlur={formik.handleBlur}
                        value={formik.values?.phoneNumber}
                        onChange={(e) => {
                          formik.setFieldValue(
                            'phoneNumber',
                            formatPhoneNumber(e.target.value)
                          )
                        }}
                        error={
                          !!formik.errors.phoneNumber &&
                          formik.touched.phoneNumber
                        }
                        helperText={
                          !!formik.errors.phoneNumber &&
                          formik.touched.phoneNumber
                            ? formik.errors.phoneNumber
                            : ''
                        }
                      />
                    </div>
                  </Grid>
                  <Grid item sm={4} xs={12}>
                    <div className="form-group">
                      <Textfield
                        variant="outlined"
                        type="text"
                        label="Google Location Id"
                        name="googleLocationId"
                        handleBlur={formik.handleBlur}
                        value={formik.values?.googleLocationId}
                        onChange={formik.handleChange}
                        error={
                          !!formik.errors.googleLocationId &&
                          formik.touched.googleLocationId
                        }
                        helperText={
                          !!formik.errors.googleLocationId &&
                          formik.touched.googleLocationId
                            ? formik.errors.googleLocationId
                            : ''
                        }
                      />
                    </div>
                  </Grid>
                  <Grid item sm={4} xs={12}>
                    <div className="form-group">
                      {!!posList.length && (
                        <Select
                          name="posId"
                          label="Select POS"
                          formikValue={formik.values?.posId}
                          handleSelectValue={(value) => {
                            formik.setFieldValue('posId', value)
                            handlePosChange(value)
                          }}
                          showError={
                            !!formik.errors.posId && formik.touched.posId
                          }
                          errorText={
                            !!formik.errors.posId && formik.touched.posId
                              ? 'POS is requied'
                              : ''
                          }
                          items={posList.map((d) => {
                            return {
                              key: d.posName,
                              value: d.posId,
                            }
                          })}
                        />
                      )}
                    </div>
                  </Grid>
                  <Grid item sm={4} xs={12}>
                    <div className="form-group">
                      <Select
                        label="Currency"
                        name="currencyId"
                        showError={
                          !!formik.errors.currencyId &&
                          formik.touched.currencyId
                        }
                        errorText={
                          !!formik.errors.currencyId &&
                          formik.touched.currencyId
                            ? 'Currency is requied'
                            : ''
                        }
                        formikValue={formik.values?.currencyId}
                        handleSelectValue={(value) => {
                          formik.setFieldValue('currencyId', value)
                        }}
                        items={currencyList.map((d) => {
                          return {
                            key: d.currencyName,
                            value: d.currencyId,
                          }
                        })}
                      />
                    </div>
                  </Grid>
                  <Grid item sm={4} xs={12}>
                    <div className="form-group">
                      <Textfield
                        variant="outlined"
                        type="text"
                        label="Stripe Connect Account Id"
                        name="stripeConnectAccountId"
                        handleBlur={formik.handleBlur}
                        value={formik.values?.stripeConnectAccountId}
                        onChange={formik.handleChange}
                        error={
                          !!formik.errors.stripeConnectAccountId &&
                          formik.touched.stripeConnectAccountId
                        }
                        helperText={
                          !!formik.errors.stripeConnectAccountId &&
                          formik.touched.stripeConnectAccountId
                            ? formik.errors.stripeConnectAccountId
                            : ''
                        }
                      />
                    </div>
                  </Grid>
                  <Grid
                    item
                    sm={4}
                    xs={12}
                    style={{ display: 'flex', alignItems: 'center' }}
                  >
                    <div className="form-group">
                      <span className="enable-two-fa">Guest Auto Opt-in</span>
                      &nbsp;&nbsp;
                      <Switch
                        name="guestAutoOptIn"
                        onChange={(e) => {
                          formik.setFieldValue(
                            'guestAutoOptIn',
                            e.target.checked
                          )
                        }}
                        // defaultChecked={formik.values.guestAutoOptIn}
                        checked={formik.values?.guestAutoOptIn}
                      />
                    </div>
                  </Grid>
                  {(currentRestaurantData?.posId === PIXELPOINT_POS_ID ||
                    currentRestaurantData?.posId === SQUIRREL_POS_ID) && (
                    <Grid item sm={4} xs={12}>
                      <div className="form-group">
                        <Textfield
                          variant="outlined"
                          type="text"
                          label="Station Info"
                          name="stationInfo"
                          handleBlur={formik.handleBlur}
                          value={formik.values?.stationInfo}
                          onChange={(e) => {
                            formik.setFieldValue('stationInfo', e.target.value)
                          }}
                        />
                      </div>
                    </Grid>
                  )}
                  {isEditMode &&
                    (currentRestaurantData?.posId === PIXELPOINT_POS_ID ||
                      currentRestaurantData?.posId === SQUIRREL_POS_ID) && (
                      <Grid item sm={4} xs={12}>
                        <div className="form-group">
                          <Textfield
                            variant="outlined"
                            type="text"
                            label="Agent Version"
                            name="agentVersion"
                            disable
                            handleBlur={formik.handleBlur}
                            value={formik.values?.agentVersion}
                            onChange={(e) => {
                              formik.setFieldValue(
                                'agentVersion',
                                e.target.value
                              )
                            }}
                          />
                        </div>
                      </Grid>
                    )}
                </Grid>
              </Grid>
              <Grid item lg={4} xs={12}>
                {/* file-upload start */}
                <div className="file-upload-wrapper">
                  <h6>Location Logo</h6>
                  <small>
                    Files Supported : JPG,JPEG or PNG, Maximum File size : 1 mb
                  </small>
                  <FileUpload
                    fileChange={handleFileChange}
                    logoUpload
                    previewHeight="224px"
                    filePath={
                      file == null ? formik.values?.businessLogoPath : null
                    }
                    fileTypes={['JPG', 'PNG', 'JPEG']}
                    maxSize={1}
                    pullName={(fName?: string) => {
                      pullName(fName)
                    }}
                  />
                </div>
                {/* file-upload end */}
              </Grid>
            </Grid>
            {showGuestConfig && (
              <>
                <h6 className="section-title">Guest Configuration</h6>
                <Grid container spacing={6}>
                  <Grid item lg={8} xs={12}>
                    <Grid container spacing={6}>
                      <Grid
                        item
                        sm={4}
                        xs={12}
                        style={{ display: 'flex', alignItems: 'center' }}
                      >
                        <div className="form-group">
                          <span className="enable-two-fa">Split By Guest</span>
                          &nbsp;&nbsp;
                          <Switch
                            name="isSplitByGuest"
                            onChange={(e) => {
                              formik.setFieldValue(
                                'isSplitByGuest',
                                e.target.checked
                              )
                            }}
                            defaultChecked={formik.values.isSplitByGuest}
                          />
                        </div>
                      </Grid>
                      {formik.values.isSplitByGuest && (
                        <Grid item sm={4} xs={12}>
                          <div className="form-group">
                            <Select
                              label="Max. no of Guests"
                              name="maxNoOfGuest"
                              formikValue={formik.values?.maxNoOfGuest}
                              handleSelectValue={(value) => {
                                formik.setFieldValue('maxNoOfGuest', value)
                              }}
                              items={noOfGuestList.map((d) => {
                                return {
                                  key: d.key,
                                  value: d.value,
                                }
                              })}
                              showError={
                                !!formik.errors.maxNoOfGuest &&
                                formik.touched.maxNoOfGuest
                              }
                              errorText={
                                !!formik.errors.maxNoOfGuest &&
                                formik.touched.maxNoOfGuest
                                  ? 'Select Max. no of Guests'
                                  : ''
                              }
                            />
                          </div>
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
                {/* <Grid item lg={4} xs={12}></Grid> */}
              </>
            )}
            <h6 className="section-title">Disbursement Configuration</h6>
            <Grid container spacing={6}>
              <Grid item sm={3} xs={12}>
                <div className="form-group">
                  <Textfield
                    variant="outlined"
                    label="Payer Id"
                    name="payerId"
                    value={
                      formik.values?.payerId === null ||
                      formik.values?.payerId === undefined
                        ? ''
                        : formik.values?.payerId
                    }
                    onChange={formik.handleChange}
                    handleBlur={formik.handleBlur}
                  />
                </div>
              </Grid>
              <Grid item sm={3} xs={12}>
                <div className="form-group">
                  <Textfield
                    variant="outlined"
                    label="Payee Name"
                    name="payeeName"
                    value={
                      formik.values?.payeeName === null ||
                      formik.values?.payeeName === undefined
                        ? ''
                        : formik.values?.payeeName
                    }
                    onChange={formik.handleChange}
                    handleBlur={formik.handleBlur}
                  />
                </div>
              </Grid>
              <Grid item sm={3} xs={12}>
                <div className="form-group">
                  <TextField
                    variant="outlined"
                    type="text"
                    label="Transaction Fee ($)"
                    name="transactionFee"
                    onBlur={formik.handleBlur}
                    value={formik.values?.transactionFee}
                    onChange={formik.handleChange}
                    error={
                      !!formik.errors.transactionFee &&
                      formik.touched.transactionFee
                    }
                    helperText={
                      !!formik.errors.transactionFee &&
                      formik.touched.transactionFee
                        ? formik.errors.transactionFee
                        : ''
                    }
                    InputProps={{
                      startAdornment: (
                        <InputAdornment
                          position="start"
                          style={{ borderRight: '0px' }}
                        >
                          $
                        </InputAdornment>
                      ),
                    }}
                  />
                </div>
              </Grid>

              <Grid item sm={3} xs={12}>
                <div className="form-group">
                  <TextField
                    variant="outlined"
                    type="text"
                    label="Interchange Fee (%)"
                    InputLabelProps={
                      formik.values?.transactionPercentage
                        ? { shrink: true }
                        : {}
                    }
                    name="transactionPercentage"
                    onBlur={formik.handleBlur}
                    value={formik.values?.transactionPercentage}
                    onChange={formik.handleChange}
                    error={
                      !!formik.errors.transactionPercentage &&
                      formik.touched.transactionPercentage
                    }
                    helperText={
                      !!formik.errors.transactionPercentage &&
                      formik.touched.transactionPercentage
                        ? formik.errors.transactionPercentage
                        : ''
                    }
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">%</InputAdornment>
                      ),
                    }}
                  />
                </div>
              </Grid>
              <Grid item sm={4} xs={12}>
                <div className="form-group">
                  <Select
                    label="Disbursement Week Starts On"
                    name="disbursementWeekStart"
                    formikValue={formik.values?.disbursementWeekStart}
                    handleSelectValue={(value) => {
                      formik.setFieldValue('disbursementWeekStart', value)
                    }}
                    items={weekList.map((d) => {
                      return {
                        key: d.key,
                        value: d.value,
                      }
                    })}
                  />
                </div>
              </Grid>
              <Grid item sm={4} xs={12}>
                <div className="form-group">
                  <Select
                    label="Disbursement Week Ends On"
                    name="disbursementWeekEnd"
                    formikValue={formik.values?.disbursementWeekEnd}
                    handleSelectValue={(value) => {
                      formik.setFieldValue('disbursementWeekEnd', value)
                    }}
                    items={weekList.map((d) => {
                      return {
                        key: d.key,
                        value: d.value,
                      }
                    })}
                  />
                </div>
              </Grid>
            </Grid>
            <h6 className="section-title">Tip Configuration</h6>
            <Grid container spacing={6}>
              <Grid item sm={4} xs={12}>
                <div className="form-group">
                  <Textfield
                    variant="outlined"
                    name="tipOne"
                    label="Option 1 %"
                    type="number"
                    value={
                      formik.values?.tipOne === null ||
                      formik.values?.tipOne === undefined
                        ? ''
                        : formik.values?.tipOne
                    }
                    onChange={formik.handleChange}
                    error={!!formik.errors.tipOne && formik.touched.tipOne}
                    helperText={
                      !!formik.errors.tipOne && formik.touched.tipOne
                        ? formik.errors.tipOne
                        : ''
                    }
                    handleBlur={formik.handleBlur}
                  />
                </div>
              </Grid>
              <Grid item sm={4} xs={12}>
                <div className="form-group">
                  <Textfield
                    variant="outlined"
                    name="tipTwo"
                    label="Option 2 %"
                    type="number"
                    value={
                      formik.values?.tipTwo === null ||
                      formik.values?.tipTwo === undefined
                        ? ''
                        : formik.values?.tipTwo
                    }
                    onChange={formik.handleChange}
                    error={!!formik.errors.tipTwo && formik.touched.tipTwo}
                    helperText={
                      !!formik.errors.tipTwo && formik.touched.tipTwo
                        ? formik.errors.tipTwo
                        : ''
                    }
                    handleBlur={formik.handleBlur}
                  />
                </div>
              </Grid>
              <Grid item sm={4} xs={12}>
                <div className="form-group">
                  <Textfield
                    variant="outlined"
                    name="tipThree"
                    type="number"
                    value={
                      formik.values?.tipThree === null ||
                      formik.values?.tipThree === undefined
                        ? ''
                        : formik.values?.tipThree
                    }
                    onChange={formik.handleChange}
                    error={!!formik.errors.tipThree && formik.touched.tipThree}
                    helperText={
                      !!formik.errors.tipThree && formik.touched.tipThree
                        ? formik.errors.tipThree
                        : ''
                    }
                    label="Option 3 %"
                    handleBlur={formik.handleBlur}
                  />
                </div>
              </Grid>
            </Grid>
            <h6 className="section-title">Service Configuration</h6>
            <Grid container spacing={4}>
              <Grid item sm={3} xs={12}>
                <div className="form-group">
                  <FormControlLabel
                    name="webhook"
                    control={
                      <Checkbox
                        color="primary"
                        checked={formik.values.webhook}
                        onChange={(e) => {
                          formik.handleChange(e)
                          setOpenChangeConfigurationDialog(true)
                          setOpenChangeConfigurationMessage(
                            'Enable/Disable this option will start/stop webhook data pulling.'
                          )
                        }}
                      />
                    }
                    label={<span style={{ color: '#737373' }}>Webhook</span>}
                  />
                </div>
              </Grid>
              <Grid item sm={3} xs={12}>
                <div className="form-group">
                  <FormControlLabel
                    name="tableApi"
                    control={
                      <Checkbox
                        color="primary"
                        checked={formik.values.tableApi}
                        onChange={(e) => {
                          formik.handleChange(e)
                          setOpenChangeConfigurationDialog(true)
                          setOpenChangeConfigurationMessage(
                            'Enable/Disable this option will start/stop table data pulling.'
                          )
                        }}
                      />
                    }
                    label={<span style={{ color: '#737373' }}>Table API</span>}
                  />
                </div>
              </Grid>
              <Grid item sm={3} xs={12}>
                <div className="form-group">
                  <FormControlLabel
                    name="paymentApi"
                    control={
                      <Checkbox
                        color="primary"
                        checked={formik.values.paymentApi}
                        onChange={(e) => {
                          formik.handleChange(e)
                          setOpenChangeConfigurationDialog(true)
                          setOpenChangeConfigurationMessage(
                            'Enable/Disable this option will start/stop payment post to POS.'
                          )
                        }}
                      />
                    }
                    label={
                      <span style={{ color: '#737373' }}>Payment API</span>
                    }
                  />
                </div>
              </Grid>
              <Grid item sm={3} xs={12}>
                <div className="form-group">
                  <FormControlLabel
                    name="isShowGoogleReview"
                    control={
                      <Checkbox
                        color="primary"
                        checked={formik.values.isShowGoogleReview}
                        onChange={(e) => {
                          formik.handleChange(e)
                        }}
                      />
                    }
                    label={
                      <span style={{ color: '#737373' }}>
                        Google Review Redirect
                      </span>
                    }
                  />
                </div>
              </Grid>
              <Grid item sm={3} xs={12}>
                <div className="form-group">
                  <FormControlLabel
                    name="isShowReview"
                    control={
                      <Checkbox
                        color="primary"
                        checked={formik.values.isShowReview}
                        onChange={(e) => {
                          formik.handleChange(e)
                        }}
                      />
                    }
                    label={
                      <span style={{ color: '#737373' }}>Show Review</span>
                    }
                  />
                </div>
              </Grid>
              <Grid item sm={3} xs={12}>
                <div className="form-group">
                  <FormControlLabel
                    name="isSubscriptionActive"
                    control={
                      <Checkbox
                        color="primary"
                        checked={formik.values.isSubscriptionActive}
                        onChange={formik.handleChange}
                      />
                    }
                    label={
                      <span style={{ color: '#737373' }}>
                        Monthly Subscription
                      </span>
                    }
                  />
                </div>
              </Grid>
              <Grid item sm={3} xs={12}>
                <div className="form-group">
                  <FormControlLabel
                    name="isLocationActive"
                    control={
                      <Checkbox
                        color="primary"
                        checked={formik.values.isLocationActive}
                        onChange={formik.handleChange}
                      />
                    }
                    label={
                      <span style={{ color: '#737373' }}>Location Active</span>
                    }
                  />
                </div>
              </Grid>
            </Grid>
            {/* {formik.values.isSubscriptionActive && ( */}
            <Collapse in={formik.values.isSubscriptionActive}>
              <div className="monthly-subscription">
                {formik.values.isSubscriptionActive && (
                  <Grid container spacing={5}>
                    <Grid item lg={12} sm={12} xs={12}>
                      <div className="form-group">
                        <span className="enable-two-fa">
                          Use Existing Customer
                        </span>
                        {'  '}
                        <Switch
                          name="isExistingCustomer"
                          onChange={(e) => {
                            formik.setFieldValue(
                              'stripeMonthlySubscription.isExistingCustomer',
                              e.target.checked
                            )
                          }}
                          defaultChecked={
                            formik.values.stripeMonthlySubscription
                              .isExistingCustomer
                          }
                        />
                      </div>
                    </Grid>
                    {formik.values.stripeMonthlySubscription
                      .isExistingCustomer && (
                      <>
                        <Grid item lg={6} sm={6} xs={12}>
                          <div className="form-group">
                            <SelectStringId
                              label="Select Customer"
                              searchAble
                              showError={
                                !!formik.errors.stripeMonthlySubscription
                                  ?.customerId &&
                                formik.touched.stripeMonthlySubscription
                                  ?.customerId
                              }
                              errorText={
                                !!formik.errors.stripeMonthlySubscription
                                  ?.customerId &&
                                formik.touched.stripeMonthlySubscription
                                  ?.customerId
                                  ? 'Please select Customer'
                                  : ''
                              }
                              handleSelectValue={(value) => {
                                formik.setFieldValue(
                                  'stripeMonthlySubscription.customerId',
                                  value
                                )
                                getCustomerDetails(value, false)
                              }}
                              items={stripeCustomerList}
                              formikValue={
                                formik.values.stripeMonthlySubscription
                                  .customerId
                              }
                            />
                          </div>
                        </Grid>
                        <Grid item lg={6} sm={6} xs={12}>
                          {formik.values.stripeMonthlySubscription
                            .customerId && (
                            <span>
                              <span style={{ fontWeight: 600 }}>
                                Card Number And Card:
                              </span>{' '}
                              {cardNumberAndCard}
                            </span>
                          )}
                        </Grid>
                      </>
                    )}
                    <Grid item lg={6} sm={6} xs={12}>
                      <div className="form-group">
                        <SelectStringId
                          label="Select Plan"
                          searchAble
                          showError={
                            !!formik.errors.stripeMonthlySubscription
                              ?.priceId &&
                            formik.touched.stripeMonthlySubscription?.priceId
                          }
                          errorText={
                            !!formik.errors.stripeMonthlySubscription
                              ?.priceId &&
                            formik.touched.stripeMonthlySubscription?.priceId
                              ? 'Please select plan'
                              : ''
                          }
                          handleSelectValue={(value) => {
                            formik.setFieldValue(
                              'stripeMonthlySubscription.priceId',
                              value
                            )
                          }}
                          items={priceIdList}
                          formikValue={
                            formik.values.stripeMonthlySubscription.priceId
                          }
                        />
                      </div>
                    </Grid>
                    <Grid item lg={6} sm={6} xs={12}>
                      <div className="form-group">
                        <Datepicker
                          datePickerlabel="Trial End Date"
                          isPromoCodePage
                          value={
                            subscriptionStartDate ??
                            convertUTCDateToLocalDate(new Date())
                          }
                          disabled={isSubscriptionActiveState}
                          onChange={(date) => {
                            if (date) {
                              formik.setFieldValue(
                                'stripeMonthlySubscription.subscriptionStartDate',
                                convertUTCDateToLocalDate(date)
                              )
                              setSubscriptionStartDate(
                                convertUTCDateToLocalDate(date)
                              )
                            } else {
                              formik.setFieldValue(
                                'stripeMonthlySubscription.subscriptionStartDate',
                                null
                              )
                              setSubscriptionStartDate(null)
                            }
                          }}
                          setFilterSelectedDate={setSubscriptionStartDate}
                          minimumDate={
                            subscriptionStartDate <
                            convertUTCDateToLocalDate(new Date())
                              ? new Date(subscriptionStartDate)
                              : new Date()
                          }
                          error={
                            !!formik.errors.stripeMonthlySubscription
                              ?.subscriptionStartDate &&
                            formik.touched.stripeMonthlySubscription
                              ?.subscriptionStartDate
                          }
                          helperText={
                            formik.touched.stripeMonthlySubscription
                              ?.subscriptionStartDate &&
                            formik.errors.stripeMonthlySubscription
                              ?.subscriptionStartDate
                              ? formik.errors.stripeMonthlySubscription
                                  .subscriptionStartDate
                              : ''
                          }
                        />
                      </div>
                    </Grid>
                    <Grid item lg={4} sm={6} xs={12}>
                      <div className="form-group">
                        <Textfield
                          variant="outlined"
                          type="text"
                          label="Restaurant Owner Email"
                          name="stripeMonthlySubscription.restOwnerEmail"
                          value={
                            formik.values.stripeMonthlySubscription
                              ?.restOwnerEmail || ''
                          }
                          disable={
                            formik.values.stripeMonthlySubscription
                              .isExistingCustomer
                          }
                          onChange={formik.handleChange}
                          // onBlur={formik.handleBlur}
                          error={
                            !!formik.errors.stripeMonthlySubscription
                              ?.restOwnerEmail &&
                            formik.touched.stripeMonthlySubscription
                              ?.restOwnerEmail
                          }
                          helperText={
                            formik.touched.stripeMonthlySubscription
                              ?.restOwnerEmail &&
                            formik.errors.stripeMonthlySubscription
                              ?.restOwnerEmail
                              ? formik.errors.stripeMonthlySubscription
                                  .restOwnerEmail
                              : ''
                          }
                        />
                      </div>
                    </Grid>
                    <Grid item lg={4} sm={6} xs={12}>
                      <div className="form-group">
                        <Textfield
                          variant="outlined"
                          type="text"
                          label="First Name"
                          name="stripeMonthlySubscription.restOwnerFirstName"
                          value={
                            formik.values.stripeMonthlySubscription
                              ? formik.values.stripeMonthlySubscription
                                  .restOwnerFirstName
                              : ''
                          }
                          onChange={formik.handleChange}
                          error={
                            !!formik.errors.stripeMonthlySubscription
                              ?.restOwnerFirstName &&
                            formik.touched.stripeMonthlySubscription
                              ?.restOwnerFirstName
                          }
                          disable={
                            formik.values.stripeMonthlySubscription
                              .isExistingCustomer
                          }
                          helperText={
                            formik.touched.stripeMonthlySubscription
                              ?.restOwnerFirstName &&
                            formik.errors.stripeMonthlySubscription
                              ?.restOwnerFirstName
                              ? formik.errors.stripeMonthlySubscription
                                  .restOwnerFirstName
                              : ''
                          }
                          inputLabelProps={{ shrink: true }}
                        />
                      </div>
                    </Grid>
                    <Grid item lg={4} sm={6} xs={12}>
                      <div className="form-group">
                        <Textfield
                          variant="outlined"
                          type="text"
                          label="Last Name"
                          name="stripeMonthlySubscription.restOwnerLastName"
                          value={
                            formik.values.stripeMonthlySubscription
                              ? formik.values.stripeMonthlySubscription
                                  .restOwnerLastName
                              : ''
                          }
                          disable={
                            formik.values.stripeMonthlySubscription
                              .isExistingCustomer
                          }
                          onChange={formik.handleChange}
                          error={
                            !!formik.errors.stripeMonthlySubscription
                              ?.restOwnerLastName &&
                            formik.touched.stripeMonthlySubscription
                              ?.restOwnerLastName
                          }
                          helperText={
                            formik.touched.stripeMonthlySubscription
                              ?.restOwnerLastName &&
                            formik.errors.stripeMonthlySubscription
                              ?.restOwnerLastName
                              ? formik.errors.stripeMonthlySubscription
                                  .restOwnerLastName
                              : ''
                          }
                          inputLabelProps={{ shrink: true }}
                        />
                      </div>
                    </Grid>
                  </Grid>
                )}
              </div>
            </Collapse>
            <div className="pos-config">
              <div className="top">
                <h6>POS Configuration</h6>
                <Button
                  variant="contained"
                  color="primary"
                  title="Add New"
                  className="icon-btn"
                  size="small"
                  onClick={addPosConfigItem}
                >
                  <img src={PlusWhite} alt="ADD" />
                </Button>
              </div>
              <div className="btn-list config-row">
                <div className="form-group">
                  <Button
                    variant="contained"
                    color="primary"
                    title="Get Tender Type"
                    className="icon-btn value-btn"
                    size="small"
                    onClick={() => getTenderTypes()}
                  >
                    Get Tender Type
                  </Button>
                </div>
              </div>
              <ul>
                {posConfigItemsList.map((element, index) => {
                  return (
                    <>
                      {/* eslint-disable-next-line react/no-array-index-key */}
                      <li key={index}>
                        <div className="form-group">
                          <Textfield
                            variant="outlined"
                            type="text"
                            label="Key"
                            name="key"
                            value={posConfigItemsList[index]?.key}
                            onChange={(e) => onPosChange(index, e)}
                            disable={posConfigItemsList[index]?.readOnly}
                          />
                        </div>
                        <div className="form-group">
                          <Textfield
                            variant="outlined"
                            type="text"
                            label="Value"
                            name="value"
                            value={posConfigItemsList[index]?.value}
                            onChange={(e) => onPosChange(index, e)}
                            disable={
                              element?.key === POS_TENDER_TYPE &&
                              posConfigItemsList[index]?.readOnly
                            }
                          />
                        </div>
                        <Button
                          variant="text"
                          title="Delete"
                          disabled={posConfigItemsList[index]?.readOnly}
                          className="icon-btn light-bordered"
                          color="inherit"
                          onClick={() => {
                            removePosConfigItem(index)
                          }}
                        >
                          <img src={Delete} alt="Delete" />
                        </Button>
                      </li>
                      <span
                        id={element?.key}
                        className="pos-config-error-txt"
                      />
                    </>
                  )
                })}
                <span>
                  {PosError ? 'Both key and value is required' : null}
                </span>
              </ul>
            </div>
            <div className="btn-list">
              <Button
                variant="contained"
                type="submit"
                color="primary"
                title={isEditMode ? 'Save' : 'Add'}
              >
                {isEditMode ? 'Save' : 'Add'}
              </Button>
              <Button
                title="Cancel"
                isTypeLink
                to={isFromAddRestaurant ? '/restaurants' : '/locations'}
                variant="outlined"
                color="primary"
              >
                Cancel
              </Button>
              {isFromAddRestaurant ? (
                <></>
              ) : (
                isEditMode && (
                  <Button
                    title="Delete"
                    isTypeLink
                    onClick={() =>
                      toggleDeleteConfirmationDialog(
                        true,
                        false,
                        Number(currentId)
                      )
                    }
                    variant="outlined"
                    color="primary"
                  >
                    Delete
                  </Button>
                )
              )}
            </div>
          </form>
        </div>
      </Layout>
      <DeleteConfirmationDialog
        open={openDeleteConfirmation}
        idFieldValue={establishmentIdForDelete}
        message="Are you sure you want to delete this Location?"
        callBack={(reason: DialogOptions, idFieldValue: number) => {
          toggleDeleteConfirmationDialog(
            false,
            reason === DialogOptions.Yes,
            idFieldValue
          )
        }}
      />
      <ChangeConfigurationConfirmationDialog
        open={openChangeConfigurationDialog}
        message={openChangeConfigurationMessage}
        onClose={closeConfigurationConfirmationDialog}
      />
    </>
  )
}

export default AddEditEstablishment
